import TopNavBar from '../../common/components/TopNavBar';

const HomepageContainer = () => {
    return (
        <>
            <TopNavBar />
            <div>Homepage</div>
            <div>A single page marketing view</div>
            <div>Scroll down from a nice photo of a tennis court</div>
            <div>To the items in the top nav bar</div>
            <div>"For You"</div>
            <div>Then too "For Your Pros"</div>
            <div>Then too "For Players"</div>
            <div>etc</div>
        </>
    );
};

export default HomepageContainer;
