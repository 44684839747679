import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useClinics } from '../../../common/hooks/api/useClinics';
import { useEvents } from '../../../common/hooks/api/useEvents';
import {
    Clinic,
    ClinicInstance,
    EventInstance,
    ScheduleEventCategoryEnum,
} from '../../../common/types/ScheduleEvent';
import AddClinicGridCard from './AddClinicGridCard';
import ClinicGridCard from './ClinicGridCard';
import ClinicInstanceGridCard from './ClinicInstanceGridCard';

const Clinics = () => {
    const { clinics, isLoadingClinics } = useClinics('client-1', DateTime.now().toISODate());
    const { events, isLoadingEvents } = useEvents('client-1', DateTime.now().toISODate());
    const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>((clinics || [])[0]);

    function onViewInstances(clinic: Clinic) {
        setSelectedClinic(clinic);
    }

    const clinicInstances: ClinicInstance[] = useMemo(() => {
        if (selectedClinic === null) return [];
        const clinicInstances: ClinicInstance[] = [];
        (events || []).forEach((event: EventInstance) => {
            if (
                event.eventCategory === ScheduleEventCategoryEnum.ClinicInstance &&
                (event as ClinicInstance).parentClinicId === selectedClinic?.clinicId
            ) {
                clinicInstances.push(event as ClinicInstance);
            }
        });
        return clinicInstances;
    }, [selectedClinic]);

    return (
        <div className='dashboard-schedule'>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>User stories to accomplish here:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        This is for creating new clinics and editing existing clinics.
                    </Typography>
                    <Typography paragraph>
                        This is not for daily operations such as checking players into a clinic instance.
                    </Typography>
                    <Typography paragraph>1. View/edit existing clinics</Typography>
                    <Typography paragraph>2. View/edit clinic details: description</Typography>
                    <Typography paragraph>3. View/edit clinic details: sport</Typography>
                    <Typography paragraph>4. View/edit clinic details: courts</Typography>
                    <Typography paragraph>5. View/edit clinic details: name</Typography>
                    <Typography paragraph>6. View/edit clinic details: max players</Typography>
                    <Typography paragraph>7. View/edit clinic details: price per player</Typography>
                    <Typography paragraph>8. View/edit clinic details: staff</Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>
                        9. View/edit clinic details: start/end times, repeat occurrance config
                    </Typography>
                    <Typography paragraph>10. View/edit clinic details: release date</Typography>
                    <Typography paragraph>
                        11. Create clinic instances based on repeat occurrance config
                    </Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>12. View/edit clinic instance details: staff</Typography>
                    <Typography paragraph>13. View/edit clinic instance details: timeslot</Typography>
                    <Typography paragraph>14. View/edit clinic instance details: courts</Typography>
                    <Typography paragraph>15. View/edit clinic instance details: price per player</Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>16. Create new clinics</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        1. X - An overflowing grid of cards: a card for each clinic
                    </Typography>
                    <Typography paragraph>
                        2. X - Click to edit clinic details in drawer: description
                    </Typography>
                    <Typography paragraph>3. X - Click to edit clinic details in drawer: sport</Typography>
                    <Typography paragraph>4. X - Click to edit clinic details in drawer: courts</Typography>
                    <Typography paragraph>5. X - Click to edit clinic details in drawer: name</Typography>
                    <Typography paragraph>6. Click to edit clinic details in drawer: max players</Typography>
                    <Typography paragraph>
                        7. Click to edit clinic details in drawer: price per player
                    </Typography>
                    <Typography paragraph>8. Click to edit clinic details in drawer: staff</Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>
                        9. Select to view/edit schedule details below: start/end times, repeat occurrances
                    </Typography>
                    <Typography paragraph>
                        10. Select to view/edit schedule details below: release date
                    </Typography>
                    <Typography paragraph>
                        11. Button to generate instances from repeat occurrances config
                    </Typography>
                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>12. Click to edit instance details in drawer: staff</Typography>
                    <Typography paragraph>13. Click to edit instance details in drawer: timeslot</Typography>
                    <Typography paragraph>14. Click to edit instance details in drawer: courts</Typography>
                    <Typography paragraph>
                        15. Click to edit instance details in drawer: price per player
                    </Typography>

                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>16. Click add new card to create new clinic</Typography>
                </AccordionDetails>
            </Accordion>

            <Typography variant='h3' gutterBottom>
                Clinics
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Box mb={6}>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='left' spacing={2}>
                            {(clinics || []).map((clinic: Clinic) => (
                                <Grid key={clinic.clinicId} item>
                                    <ClinicGridCard clinic={clinic} onViewInstances={onViewInstances} />
                                </Grid>
                            ))}
                            <Grid key={'add-clinic'} item>
                                <AddClinicGridCard onAddNewClinic={() => {}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {selectedClinic && (
                <Box>
                    <Typography variant='h5' gutterBottom>
                        All Classes: {selectedClinic.eventName}
                        <Typography variant='body1' sx={{ float: 'right', mr: 3 }}>
                            {clinicInstances.length} Classes
                        </Typography>
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='left' spacing={2}>
                                {clinicInstances.map((instance: ClinicInstance) => (
                                    <Grid key={instance.eventId} item>
                                        <ClinicInstanceGridCard clinicInstance={instance} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </div>
    );
};

export default Clinics;
