import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Facility } from '../../../../common/types/Facility';
import AddFacilityGridCard from './AddFacilityGridCard';
import FacilityGridCard from './FacilityGridCard';

interface FacilityCardGridProps {
    facilities: Facility[];
    onSelectFacility: (facility: Facility) => void;
}

const FacilityCardGrid = ({ facilities, onSelectFacility }: FacilityCardGridProps) => {
    const [selectedFacility, setSelectedFacility] = useState<Facility>(facilities[0]);

    function selectFacility(facility?: Facility) {
        if (facility) {
            setSelectedFacility(facility);
            onSelectFacility(facility);
        } else {
            setSelectedFacility(facilities[0]);
            onSelectFacility(facilities[0]);
        }
    }

    return (
        <Box m={2} mb={6}>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent='left' spacing={2}>
                        {facilities.length &&
                            facilities.map((facility: Facility) => (
                                <Grid key={facility.facilityId} item>
                                    <FacilityGridCard
                                        selected={selectedFacility?.facilityId === facility.facilityId}
                                        facility={facility}
                                        onSelectFacility={selectFacility}
                                    />
                                </Grid>
                            ))}
                        <Grid key={'add-facility'} item>
                            <AddFacilityGridCard />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FacilityCardGrid;
