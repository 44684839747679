import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Clinic } from '../../../common/types/ScheduleEvent';
import { DateUtils } from '../../../common/utils/DateUtils';
import { ScheduleUtils } from '../Schedule/ScheduleUtils';

interface OccurrancesProps {
    clinic: Clinic;
    startDate: DateTime;
    repeatQty: number;
    repeatDays: string[];
    onChangeStartDate: (startDate: DateTime | null) => void;
    onChangeRepeatQty: (repeatQty: number) => void;
    onChangeRepeatDays: (repeatDays: string[]) => void;
}

const Occurances = ({
    clinic,
    startDate,
    repeatQty,
    repeatDays,
    onChangeStartDate,
    onChangeRepeatQty,
    onChangeRepeatDays,
}: OccurrancesProps) => {
    const getExpectedNumberOfInstancesToBeCreated = useMemo(() => {
        return ScheduleUtils.getExpectedNumberOfInstancesToBeCreated(
            clinic,
            startDate,
            repeatQty,
            repeatDays
        );
    }, [clinic, startDate, repeatQty, repeatDays]);
    return (
        <>
            <Typography variant='h5' gutterBottom>
                Repeat Occurances
            </Typography>
            <Divider sx={{ mt: 1, mb: 3 }} />
            <Grid container spacing={1} p={1}>
                <DatePicker
                    sx={{ width: 'calc(50% - 0.5rem)', mb: 3 }}
                    label='Start Date'
                    value={startDate}
                    onChange={(newValue) => onChangeStartDate(newValue)}
                />
                <TextField
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    sx={{ width: 'calc(50% - 0.5rem)', mb: 3, ml: 2 }}
                    label='Repeat # Weeks'
                    value={repeatQty}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeRepeatQty(Number(event.target.value));
                    }}
                />
                <Grid item xs={12}>
                    <Stack direction='row' justifyContent='space-between' spacing={1}>
                        {DateUtils.getDaysOfWeek().map((day) => (
                            <Box key={day}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                repeatDays.includes(day) || startDate?.weekdayLong === day
                                            }
                                            onChange={() => {
                                                if (repeatDays.includes(day)) {
                                                    onChangeRepeatDays(repeatDays.filter((d) => d !== day));
                                                } else {
                                                    onChangeRepeatDays([...repeatDays, day]);
                                                }
                                            }}
                                        />
                                    }
                                    label={day.slice(0, 2)}
                                />
                            </Box>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
            <div>
                <Typography style={{ marginBottom: 0 }}>
                    This will create {getExpectedNumberOfInstancesToBeCreated} event instances on your
                    schedule.
                </Typography>
                <Typography style={{ marginTop: 0, marginBottom: 0 }}>
                    Each of the {getExpectedNumberOfInstancesToBeCreated / clinic.courtIds.length} clinic(s)
                    is on {clinic.courtIds.length} court(s).
                </Typography>
                {getExpectedNumberOfInstancesToBeCreated <
                    repeatDays.length * repeatQty * clinic.courtIds.length && (
                    <Typography style={{ marginTop: 0 }}>
                        Any days of the week checked off that are before today have been ignored for the first
                        week.
                    </Typography>
                )}
            </div>
        </>
    );
};

export default Occurances;
