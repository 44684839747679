export enum CourtSurfaceEnum {
    Hard = 'Hard',
    Clay = 'Clay',
    Grass = 'Grass',
    HarTru = 'HarTru',
    Carpet = 'Carpet',
    Other = 'Other',
}

export enum CourtLocationEnum {
    Indoor = 'Indoor',
    Outdoor = 'Outdoor',
}

export enum CourtSportEnum {
    Tennis = 'Tennis',
    Pickleball = 'Pickleball',
}

export type Court = {
    courtId: string;
    clientId: string;
    facilityId: string;
    courtSurface: CourtSurfaceEnum;
    courtLocation: CourtLocationEnum;
    courtName: string;
    maxPlayers: number;
    sport: CourtSportEnum;
};
