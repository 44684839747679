import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const Lessons = () => {
    return (
        <div className='dashboard-schedule'>
            <Typography variant='h3' gutterBottom>
                Lessons
            </Typography>
            <Typography variant='h6'>User stories to accomplish here:</Typography>
            <Typography paragraph>1. See existing lessons in a calendar view (month, week, day)</Typography>
            <Typography paragraph>2. See pro availability in the calendar view with color</Typography>
            <Typography paragraph>
                3. Click calendar during pro availability to create a lesson via drawer
            </Typography>
            <Divider sx={{ mb: 2 }} />
        </div>
    );
};

export default Lessons;
