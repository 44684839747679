import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import DrawerWithChangesDialogue from '../../../../common/components/DrawerWithChangesDialogue';
import { useCreateCourt } from '../../../../common/hooks/api/courts/useCreateCourt';
import { useUpdateCourt } from '../../../../common/hooks/api/courts/useUpdateCourt';
import { Court, CourtLocationEnum, CourtSportEnum, CourtSurfaceEnum } from '../../../../common/types/Court';

interface ConfigureCourtProps {
    court: Court;
    drawerOpen: boolean;
    onDrawerClose: () => void;
}

const ConfigureCourtDrawer = ({ court, drawerOpen, onDrawerClose }: ConfigureCourtProps) => {
    //todo fix hardcode client id
    const { updateCourtAsync, isLoading: isUpdating } = useUpdateCourt();
    //todo fix hardcode client id
    const { createCourtAsync, isLoading: isCreating } = useCreateCourt();
    const [courtName, setCourtName] = useState<string>(court.courtName);
    const [courtSport, setCourtSport] = useState<CourtSportEnum>(court.sport);
    const [courtLocation, setCourtLocation] = useState<CourtLocationEnum>(court.courtLocation);
    const [courtSurface, setCourtSurface] = useState<CourtSurfaceEnum>(court.courtSurface);
    const [courtMaxPlayers, setCourtMaxPlayers] = useState<number>(court.maxPlayers);

    const newOrEditedCourt = useMemo(() => {
        const newOrEditedCourt: Court = {
            ...court,
            courtName,
            sport: courtSport,
            courtLocation,
            courtSurface,
            maxPlayers: courtMaxPlayers,
        };
        return newOrEditedCourt;
    }, [court, courtName, courtSport, courtLocation, courtSurface, courtMaxPlayers]);

    async function onSaveChanges() {
        if (court.courtId === '') {
            await createCourtAsync(newOrEditedCourt);
        } else {
            await updateCourtAsync(newOrEditedCourt);
        }
        onDrawerClose();
    }

    function isDirty() {
        //todo it seems to always think isDirty is true
        //might be related to _id existing from mongo, idk
        return JSON.stringify(newOrEditedCourt) !== JSON.stringify(court);
    }

    return (
        <DrawerWithChangesDialogue
            drawerOpen={drawerOpen}
            onDrawerClose={onDrawerClose}
            isDirty={isDirty}
            onSaveChanges={onSaveChanges}
            isLoading={isUpdating || isCreating}
        >
            <Box p={3} width={'600px'}>
                <Typography variant='h5'>Court Info</Typography>
                <Divider sx={{ mt: 1, mb: 4 }} />

                <Grid container spacing={1} p={1}>
                    <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        label='Court Name'
                        value={courtName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCourtName(event.target.value);
                        }}
                    />

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id='sport-label' shrink>
                            Sport
                        </InputLabel>
                        <Select
                            labelId='sport-label'
                            id='sport-select'
                            value={courtSport}
                            label='Sport'
                            onChange={(event: SelectChangeEvent<string>) => {
                                setCourtSport(event.target.value as CourtSportEnum);
                            }}
                        >
                            {(Object.keys(CourtSportEnum) as Array<keyof typeof CourtSportEnum>).map(
                                (sport: keyof typeof CourtSportEnum) => {
                                    return (
                                        <MenuItem key={sport} value={CourtSportEnum[sport]}>
                                            {CourtSportEnum[sport]}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id='location-label' shrink>
                            Location
                        </InputLabel>
                        <Select
                            labelId='location-label'
                            id='location-select'
                            value={courtLocation}
                            label='Location'
                            onChange={(event: SelectChangeEvent<string>) => {
                                setCourtLocation(event.target.value as CourtLocationEnum);
                            }}
                        >
                            {(Object.keys(CourtLocationEnum) as Array<keyof typeof CourtLocationEnum>).map(
                                (location: keyof typeof CourtLocationEnum) => {
                                    return (
                                        <MenuItem key={location} value={CourtLocationEnum[location]}>
                                            {CourtLocationEnum[location]}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id='surface-label' shrink>
                            Surface
                        </InputLabel>
                        <Select
                            labelId='surface-label'
                            id='surface-select'
                            value={courtSurface}
                            label='Surface'
                            onChange={(event: SelectChangeEvent<string>) => {
                                setCourtSurface(event.target.value as CourtSurfaceEnum);
                            }}
                        >
                            {(Object.keys(CourtSurfaceEnum) as Array<keyof typeof CourtSurfaceEnum>).map(
                                (surface: keyof typeof CourtSurfaceEnum) => {
                                    return (
                                        <MenuItem key={surface} value={CourtSurfaceEnum[surface]}>
                                            {CourtSurfaceEnum[surface]}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        sx={{ mb: 3 }}
                        label='Max Players'
                        value={courtMaxPlayers}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCourtMaxPlayers(Number(event.target.value));
                        }}
                    />
                </Grid>
            </Box>
        </DrawerWithChangesDialogue>
    );
};

export default ConfigureCourtDrawer;
