import { useQuery } from '@tanstack/react-query';
import { QueryConstants } from '../../constants/QueryContstants';
import { EventInstance } from '../../types/ScheduleEvent';
import { CommonUtils } from '../../utils/CommonUtils';
// import { ScheduleUtils } from '../../features/Dashboard/Schedule/ScheduleUtils';
// import { MOCK_SCHEDULED_EVENTS } from '../../mockData/mockEvents';

export const useEvents = (
    clientId: string,
    isoDateString: string | null
): { events: EventInstance[]; isLoadingEvents: boolean } => {
    const { data: events, isLoading: isLoadingEvents } = useQuery({
        queryKey: [QueryConstants.CLIENT_EVENTS, isoDateString],
        queryFn: () =>
            // fetch('https://jsonplaceholder.typicode.com/photos').then((res) => {
            //     return ScheduleUtils.getScheduleEventsForClientId(MOCK_SCHEDULED_EVENTS, clientId);
            // }),
            fetch(`${CommonUtils.getBaseUrl()}/events/${clientId}`).then((res) => {
                return res.json();
            }),
        enabled: !!isoDateString && !!clientId,
    });
    return { events, isLoadingEvents };
};
