import {
    Box,
    FormControl,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import DrawerWithChangesDialogue from '../../../../common/components/DrawerWithChangesDialogue';
import { useCourts } from '../../../../common/hooks/api/courts/useCourts';
import { useEvents } from '../../../../common/hooks/api/useEvents';
import { useUpdateEvent } from '../../../../common/hooks/api/useUpdateEvent';
import { Court } from '../../../../common/types/Court';
import { Player } from '../../../../common/types/Player';
import {
    ClinicInstance,
    EventInstance,
    Lesson,
    ScheduleEventCategoryEnum,
    ScheduleEventCategoryEnumArray,
} from '../../../../common/types/ScheduleEvent';
import { CourtUtils } from '../../../../common/utils/CourtUtils';
import { DateUtils } from '../../../../common/utils/DateUtils';
import PaymentForm from './PaymentForm';
import PlayersList from './PlayersList';
import StaffList from './StaffList';

interface EventDetailsProps {
    event: EventInstance;
    drawerOpen: boolean;
    onDrawerClose: () => void;
}

const EventDetailsDrawer = ({ event, drawerOpen, onDrawerClose }: EventDetailsProps) => {
    const { courts } = useCourts(event.clientId);
    const { events } = useEvents(event.clientId, event.timeSlot.start);
    const { updateEvent } = useUpdateEvent(event);

    const [eventCategory, setEventCategory] = useState<ScheduleEventCategoryEnum>(event.eventCategory);
    const [eventName, setEventName] = useState<string>(event.eventName);
    const [courtId, setCourtId] = useState<string>(event.courtId);
    //dates prob shouldnt be editable. if they want to change, they should go to the open event at that date?
    const [startTime, setStartTime] = useState<DateTime | null>(DateTime.fromISO(event.timeSlot.start));
    const [endTime, setEndTime] = useState<DateTime | null>(DateTime.fromISO(event.timeSlot.end));
    const [maxPlayers, setMaxPlayers] = useState<number>(event.maxPlayers);
    // const [pricePerPlayer, setPricePerPlayer] = useState<number>(event.pricePerPlayer);
    // const [staff, setStaff] = useState<Player[]>(event.staff);
    const [signedUpPlayers, setSignedUpPlayers] = useState<Player[]>(event.signedUpPlayers);
    const [checkedInPlayers, setCheckedInPlayers] = useState<Player[]>(event.checkedInPlayers);
    const [paidPlayers, setPaidPlayers] = useState<Player[]>(event.paidPlayers);
    const [lessonDescription, setLessonDescription] = useState<string>((event as Lesson).lessonDescription);
    const [clinicDescription, setClinicDescription] = useState<string>(
        (event as ClinicInstance).clinicDescription
    );

    const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
    const [paymentPlayer, setPaymentPlayer] = useState<Player | null>(null);

    useEffect(() => {
        //we need to update the state of the drawer every time it opens
        //otherwise the state will be whatever it was the last time it opened
        //for example, if you open the drawer, change the clinic sport, close the drawer, then open it again
        //the clinic sport will be whatever you changed it to, not the original clinic name
        setCourtId(event.courtId);
    }, [drawerOpen]);

    const editedEvent = useMemo(() => {
        const newEvent: EventInstance = {
            ...event,
            eventCategory,
            eventName,
            courtId: event.courtId,
            timeSlot: {
                start: startTime?.toISO() || '',
                end: endTime?.toISO() || '',
            },
            maxPlayers,
            signedUpPlayers,
            checkedInPlayers,
            paidPlayers,
        };

        if (eventCategory === ScheduleEventCategoryEnum.ClinicInstance) {
            (newEvent as ClinicInstance).clinicDescription = clinicDescription;
            (newEvent as ClinicInstance).instanceStaff = (event as ClinicInstance).instanceStaff || [];
        }
        if (eventCategory === ScheduleEventCategoryEnum.Lesson) {
            (newEvent as Lesson).lessonDescription = lessonDescription;
            (newEvent as Lesson).lessonStaff = (event as Lesson).lessonStaff || [];
        }

        return newEvent;
    }, [
        event,
        eventCategory,
        eventName,
        courtId,
        startTime,
        endTime,
        maxPlayers,
        signedUpPlayers,
        checkedInPlayers,
        paidPlayers,
        clinicDescription,
        lessonDescription,
    ]);

    const courtName = useMemo(() => {
        if (courts === undefined) return '';
        return CourtUtils.getCourtName(courtId, courts);
    }, [courtId, courts]);

    const availableCourtsDuringEventTimeSlot = useMemo(() => {
        if (events === undefined || courts === undefined) return [];
        return CourtUtils.getAvailableCourts(editedEvent, events, courts);
    }, [editedEvent, events, courts]);

    function onChangeEventCategory(category: ScheduleEventCategoryEnum) {
        setEventCategory(category);
    }

    function onSelectCourt(e: SelectChangeEvent<string>) {
        setCourtId(e.target.value);
    }

    function onClickPay(player: Player) {
        setPaymentOpen(true);
        setPaymentPlayer(player);
    }

    function processPayment(player: Player) {
        //todo: api call to process payment
        console.log('payment process for', player, event);
        //then updateEvent
        //with copy of event with paid playerId
    }

    function onSaveChanges() {
        updateEvent(event);
    }

    function isDirty() {
        return JSON.stringify(editedEvent) !== JSON.stringify(event);
    }

    return (
        <DrawerWithChangesDialogue
            drawerOpen={drawerOpen}
            onDrawerClose={onDrawerClose}
            isDirty={isDirty}
            onSaveChanges={onSaveChanges}
        >
            <Box p={3} maxWidth={'600px'}>
                <Grid container spacing={1}>
                    <Grid item xs={6} mb={3}>
                        {/* todo enable changing the date time of reservations & closed */}
                        <Typography variant='h6'>
                            {DateUtils.getWeekdayMonthDay(startTime?.toISO() || '')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} mb={3}>
                        <Typography variant='h6' align='right'>
                            {DateUtils.getDisplayTimeSlot(startTime?.toISO() || '', endTime?.toISO() || '')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} mb={2}>
                        {/* can't modify clinic instances or lessons here. go to their views to do it
                            todo: put some sort of tooltip telling the user this */}
                        {event.eventCategory === ScheduleEventCategoryEnum.ClinicInstance ||
                        event.eventCategory === ScheduleEventCategoryEnum.Lesson ? (
                            <Typography variant='h5'>{event.eventCategory}</Typography>
                        ) : (
                            <FormControl fullWidth>
                                <InputLabel id='court-select-label'>Event Type</InputLabel>
                                <Select
                                    labelId='category-select-label'
                                    id='category-select'
                                    value={eventCategory}
                                    label='Type'
                                    onChange={(e: SelectChangeEvent<string>) =>
                                        onChangeEventCategory(e.target.value as ScheduleEventCategoryEnum)
                                    }
                                    input={<OutlinedInput label='Type' />}
                                    renderValue={() => eventCategory}
                                >
                                    {ScheduleEventCategoryEnumArray.map((c: ScheduleEventCategoryEnum) => (
                                        <MenuItem key={c} value={c}>
                                            <ListItemText primary={c} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>

                    <Grid item xs={12} mb={2}>
                        <TextField
                            fullWidth
                            label='Event Name'
                            value={eventName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setEventName(e.target.value);
                            }}
                        />
                    </Grid>

                    {(eventCategory === ScheduleEventCategoryEnum.ClinicInstance ||
                        eventCategory === ScheduleEventCategoryEnum.Lesson) && (
                        <Grid item xs={12} mb={2}>
                            {eventCategory === ScheduleEventCategoryEnum.ClinicInstance && (
                                <TextField
                                    fullWidth
                                    label='Description'
                                    value={clinicDescription}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setClinicDescription(e.target.value);
                                    }}
                                    multiline
                                    rows={3}
                                    disabled={
                                        event.eventCategory === ScheduleEventCategoryEnum.ClinicInstance
                                    }
                                />
                            )}
                            {eventCategory === ScheduleEventCategoryEnum.Lesson && (
                                <TextField
                                    fullWidth
                                    label='Description'
                                    value={lessonDescription}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setLessonDescription(e.target.value);
                                    }}
                                    multiline
                                    rows={3}
                                />
                            )}
                        </Grid>
                    )}

                    <Grid item xs={12} mb={2}>
                        <FormControl fullWidth>
                            <InputLabel id='court-select-label'>Court</InputLabel>
                            <Select
                                labelId='court-select-label'
                                id='court-select'
                                value={courtId}
                                label='Court'
                                onChange={onSelectCourt}
                                input={<OutlinedInput label='Court' />}
                                renderValue={() => courtName}
                            >
                                <ListSubheader>Available Courts</ListSubheader>
                                {availableCourtsDuringEventTimeSlot.map((c: Court) => (
                                    <MenuItem key={c.courtId} value={c.courtId}>
                                        <ListItemText primary={c.courtName} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {eventCategory === ScheduleEventCategoryEnum.ClinicInstance && (
                        //todo make sure only editing the instance, not the whole clinic
                        <StaffList event={editedEvent as ClinicInstance} />
                    )}
                    {eventCategory === ScheduleEventCategoryEnum.Lesson && (
                        <StaffList event={editedEvent as Lesson} />
                    )}

                    {eventCategory !== ScheduleEventCategoryEnum.Closed && (
                        <PlayersList
                            event={editedEvent}
                            updateSignedUpPlayers={setSignedUpPlayers}
                            updateCheckedInPlayers={setCheckedInPlayers}
                            updatePaidPlayers={setPaidPlayers}
                            onClickPay={onClickPay}
                        />
                    )}

                    {paymentOpen && paymentPlayer && (
                        <PaymentForm customer={paymentPlayer} processPayment={processPayment} />
                    )}
                </Grid>
            </Box>
        </DrawerWithChangesDialogue>
    );
};

export default EventDetailsDrawer;
