import { DateTime } from 'luxon';
import { ISODateStr } from '../types/ScheduleEvent';

export class DateUtils {
    static getDisplayTime(date: ISODateStr): string {
        const dateTime = DateTime.fromISO(date);
        return `${dateTime.hour}:${dateTime.minute}`;
    }

    static getDisplayTimeSlot(start: ISODateStr, end: ISODateStr): string {
        return `${this.getDisplayTime(start)} - ${this.getDisplayTime(end)}`;
    }

    static getSlashesDate(date: ISODateStr): string {
        const dateTime = DateTime.fromISO(date);
        return dateTime.toLocaleString();
    }

    static getWeekdayMonthDay(date: ISODateStr): string {
        const dateTime = DateTime.fromISO(date);
        const dateFromDT = dateTime.toJSDate();
        return Intl.DateTimeFormat('en-US', { month: 'long', weekday: 'long', day: 'numeric' }).format(
            dateFromDT
        );
    }

    static getDaysOfWeek(): string[] {
        return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    }
}
