import { Court } from '../types/Court';
import { EventInstance } from '../types/ScheduleEvent';

export class CourtUtils {
    static getCourtNames(courtIds: string[], courts: Court[]): string {
        //which is faster/better?
        //     const eventCourts = courts?.filter((court) => courtIds.includes(court.courtId));
        //     return eventCourts?.map((court: Court) => court.courtName).join(', ') || '';
        return (
            courtIds
                .map((courtId) => {
                    const court = courts.find((court) => court.courtId === courtId);
                    return court?.courtName;
                })
                .join(', ') || ''
        );
    }

    static getCourtName(courtId: string, courts: Court[]): string {
        const court = courts.find((court) => court.courtId === courtId);
        return court?.courtName || '';
    }

    static getAvailableCourts(eventToMove: EventInstance, events: EventInstance[], courts: Court[]): Court[] {
        //start with a list of all courts, assuming all are available
        const allCourts = [...courts];
        //look at all events (for that day - should only be passed in)
        //if an event has the same time slot as the event youre looking at moving
        //find the court that event is on, because it is not available
        //and remove it from the list of available courts
        events.forEach((otherEvent) => {
            //todo better do overlap comparison so events can be more than 1 hour
            if (
                otherEvent.timeSlot.start === eventToMove.timeSlot.start &&
                otherEvent.timeSlot.end === eventToMove.timeSlot.end
            ) {
                const court = allCourts.find((court) => court.courtId === otherEvent.courtId);
                if (court && eventToMove.courtId !== court.courtId) {
                    const index = allCourts.indexOf(court);
                    allCourts.splice(index, 1);
                }
            }
        });
        return allCourts;
    }
}
