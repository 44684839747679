import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { EasyCourtsTheme } from './common/constants/ThemeContants';
import MainRoutes from './features/MainRoutes';
import './styles/App.css';
import './styles/main.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './common/components/Auth0ProviderWithNavigate';

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: { staleTime: 1000 * 60 * 2 },
    },
});

const theme = createTheme(EasyCourtsTheme);

function App() {
    return (
        <div className='easycourts-app'>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <CssBaseline />
                                <MainRoutes />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </QueryClientProvider>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </div>
    );
}

export default App;
