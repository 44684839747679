import { ProStaff } from '../common/types/ProStaff';

export const MOCK_PROS: ProStaff[] = [
    {
        proId: 'pro-1',
        clientId: 'client-1',
        firstName: 'John',
        lastName: 'Doe',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
    {
        proId: 'pro-2',
        firstName: 'Jane',
        lastName: 'Doe',
        clientId: 'client-1',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
    {
        proId: 'pro-3',
        clientId: 'client-2',
        firstName: 'Dude',
        lastName: 'Sweet',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
    {
        proId: 'pro-4',
        clientId: 'client-2',
        firstName: 'Sweet',
        lastName: 'Dude',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
    {
        proId: 'pro-5',
        clientId: 'client-3',
        firstName: 'Totally',
        lastName: 'Awesome',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
    {
        proId: 'pro-6',
        clientId: 'client-3',
        firstName: 'Wow',
        lastName: 'Omg',
        bio: 'I am a tennis pro',
        picture: 'https://www.tennis.com.au/wp-content/uploads/2019/12/tennis-coach-brisbane.jpg',
        schedule: [
            {
                start: new Date('2023-06-01T08:00:00').toISOString(),
                end: new Date('2023-06-01T17:00:00').toISOString(),
            },
            {
                start: new Date('2023-06-02T08:00:00').toISOString(),
                end: new Date('2023-06-02T17:00:00').toISOString(),
            },
        ],
        price: 100,
    },
];
