import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
// import { TimePicker } from '@mui/x-date-pickers';
// import { DateTime } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import DrawerWithChangesDialogue from '../../../common/components/DrawerWithChangesDialogue';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { useFacilities } from '../../../common/hooks/api/facilities/useFacilities';
import { useEvents } from '../../../common/hooks/api/useEvents';
import { Court, CourtSportEnum } from '../../../common/types/Court';
import { Facility } from '../../../common/types/Facility';
import { ProStaff } from '../../../common/types/ProStaff';
import { Clinic } from '../../../common/types/ScheduleEvent';
import StaffList from '../Schedule/EventDetails/StaffList';
import { ScheduleUtils } from '../Schedule/ScheduleUtils';
import Occurances from './Occurances';

interface ConfigureClinicProps {
    clinic: Clinic;
    onSave: (clinic: Clinic) => void;
    drawerOpen: boolean;
    onDrawerClose: () => void;
}

const MOCK_TODAY = new Date();

const ConfigureClinicDrawer = ({ clinic, onSave, drawerOpen, onDrawerClose }: ConfigureClinicProps) => {
    //Client properties for clinic options
    const { facilities: clientFacilities } = useFacilities(clinic.clientId);
    const { courts: clientCourts } = useCourts(clinic.clientId);
    const { events: clientEvents } = useEvents(clinic.clientId, clinic.timeSlot.start);
    const arrListElements: (Facility | Court)[] = useMemo(() => {
        if (clientFacilities !== undefined && clientCourts !== undefined) {
            return clientFacilities
                .map((facility: Facility) => {
                    const courts = clientCourts.filter((c: Court) => c.facilityId === facility.facilityId);
                    return [facility, ...(courts || [])];
                })
                .flat();
        } else {
            return [];
        }
    }, [clientFacilities, clientCourts]);

    //Clinic properties
    const [clinicName, setClinicName] = useState<string>(clinic.eventName);
    const [clinicDescription, setClinicDescription] = useState<string>(clinic.clinicDescription);
    const [maxPlayers, setMaxPlaers] = useState<number>(clinic.maxPlayers);
    const [totalPrice, setTotalPrice] = useState<number>(clinic.totalPrice);
    const [instancePrice, setInstancePrice] = useState<number>(clinic.instancePrice);
    const [startTime, setStartTime] = useState<DateTime | null>(DateTime.fromISO(clinic.timeSlot.start));
    const [endTime, setEndTime] = useState<DateTime | null>(DateTime.fromISO(clinic.timeSlot.end));
    const [clinicSport, setClinicSport] = useState<CourtSportEnum>(clinic.clinicSport);
    const [clinicCourts, setClinicCourts] = useState<string[]>(clinic.courtIds);
    const [startDate, setStartDate] = useState<DateTime>(DateTime.fromISO(clinic.timeSlot.start));
    const [repeatQty, setRepeatQty] = useState<number>(clinic.repeatOccuranceConfig.repeatQty);
    const [repeatDays, setRepeatDays] = useState<string[]>(clinic.repeatOccuranceConfig.repeatOnDays);

    useEffect(() => {
        //we need to update the state of the drawer every time it opens
        //otherwise the state will be whatever it was the last time it opened
        //for example, if you open the drawer, change the clinic sport, close the drawer, then open it again
        //the clinic sport will be whatever you changed it to, not the original clinic name
        setClinicName(clinic.eventName);
        setClinicDescription(clinic.clinicDescription);
        setMaxPlaers(clinic.maxPlayers);
        setStartTime(DateTime.fromISO(clinic.timeSlot.start));
        setEndTime(DateTime.fromISO(clinic.timeSlot.end));
        setClinicSport(clinic.clinicSport);
        setClinicCourts(clinic.courtIds);
        setStartDate(DateTime.fromISO(clinic.timeSlot.start));
        setRepeatQty(clinic.repeatOccuranceConfig.repeatQty);
        setRepeatDays(clinic.repeatOccuranceConfig.repeatOnDays);
    }, [drawerOpen]);

    function renderCourtName(selected: string[]) {
        const courtFromIds: Court[] = (clientCourts || []).filter((c) => selected.includes(c.courtId));
        return courtFromIds.map((c: Court) => `${c.courtName}`).join(', ');
    }

    function toggleCourtSelection(court: Court) {
        if (clinic.courtIds.indexOf(court.courtId) > -1) {
            clinic.courtIds = clinic.courtIds.filter((courtId) => courtId !== court.courtId);
        } else {
            clinic.courtIds.push(court.courtId);
        }
        setClinicCourts([...clinic.courtIds]);
    }

    function addNewStaff(staff: ProStaff[]) {
        clinic.clinicStaff = [...staff];
    }

    function onChangeStartDate(newStartDate: DateTime | null) {
        setStartDate(newStartDate || DateTime.fromJSDate(MOCK_TODAY));
    }

    function getNewClinic(): Clinic {
        const clinicWithPotentialEdits: Clinic = {
            ...clinic,
            eventName: clinicName,
            clinicDescription,
            clinicSport,
            courtIds: clinicCourts,
            maxPlayers,
            totalPrice,
            instancePrice,
            repeatOccuranceConfig: {
                repeatQty,
                repeatOnDays: repeatDays,
            },
        };
        if (startTime) {
            clinicWithPotentialEdits.timeSlot.start = startTime.toISO() || '';
        }
        if (endTime) {
            clinicWithPotentialEdits.timeSlot.end = endTime.toISO() || '';
        }
        return clinicWithPotentialEdits;
    }

    function onSaveChanges() {
        let overlappingEvents = false;
        const newClinic: Clinic = getNewClinic();
        //todo: need to make sure no events already exist during time slots the clinic instances will occupy
        //this logic will probably move to BE?
        const generatedInstances = ScheduleUtils.generateClinicInstances(
            newClinic,
            startDate,
            repeatQty,
            repeatDays
        );

        const overlap = ScheduleUtils.getOverlappingEvents(clientEvents || [], newClinic, generatedInstances);
        if (overlap.length > 0) {
            alert('There are already events at the dates & times & courts you are trying to create');
            console.log('overlap', overlap);
            overlappingEvents = false;
        }

        onSave(newClinic);
    }

    function isDirty() {
        //or could have an if for each property, more robust
        const newClinic: Clinic = getNewClinic();
        return JSON.stringify(newClinic) !== JSON.stringify(clinic);
    }

    return (
        <DrawerWithChangesDialogue
            drawerOpen={drawerOpen}
            onDrawerClose={onDrawerClose}
            isDirty={isDirty}
            onSaveChanges={onSaveChanges}
        >
            {drawerOpen && (
                <Box p={3} width={'600px'}>
                    <Typography variant='h5'>Clinic Info</Typography>
                    <Divider sx={{ mt: 1, mb: 4 }} />

                    <Grid container spacing={1} p={1}>
                        <TextField
                            fullWidth
                            sx={{ mb: 3 }}
                            label='Clinic Name'
                            value={clinicName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setClinicName(event.target.value);
                            }}
                        />
                        <TextField
                            fullWidth
                            sx={{ mb: 3 }}
                            label='Description'
                            value={clinicDescription}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setClinicDescription(event.target.value);
                            }}
                            multiline
                            rows={3}
                        />

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id='sport-select-label'>Clinic Sport</InputLabel>
                            <Select
                                labelId='sport-select-label'
                                id='sport-select'
                                value={clinicSport}
                                label='Clinic Sport'
                                onChange={(event: SelectChangeEvent<string>) => {
                                    setClinicSport(event.target.value as CourtSportEnum);
                                }}
                            >
                                {(Object.keys(CourtSportEnum) as Array<keyof typeof CourtSportEnum>).map(
                                    (sport: keyof typeof CourtSportEnum) => {
                                        return (
                                            <MenuItem key={sport} value={CourtSportEnum[sport]}>
                                                {CourtSportEnum[sport]}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel id='courts-select-label'>Courts</InputLabel>
                            <Select
                                labelId='courts-select-label'
                                id='courts-select'
                                value={clinicCourts}
                                label='Courts'
                                onChange={() => {}}
                                input={<OutlinedInput label='Select Courts' />}
                                renderValue={renderCourtName}
                            >
                                {arrListElements.map((facility: Facility | Court) => {
                                    if ((facility as Facility).facilityName) {
                                        return (
                                            <ListSubheader key={facility.facilityId}>
                                                {(facility as Facility).facilityName}
                                            </ListSubheader>
                                        );
                                    } else {
                                        const court = facility as Court;
                                        return (
                                            <MenuItem
                                                key={court.courtId}
                                                value={court.courtId}
                                                onClick={() => toggleCourtSelection(court)}
                                            >
                                                <Checkbox
                                                    checked={clinic.courtIds.indexOf(court.courtId) > -1}
                                                />
                                                <ListItemText primary={court.courtName} />
                                            </MenuItem>
                                        );
                                    }
                                })}
                            </Select>
                        </FormControl>

                        <StaffList event={clinic} addNewStaff={addNewStaff} />

                        <TextField
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            fullWidth
                            sx={{ mb: 3 }}
                            label='Max Players'
                            value={maxPlayers}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMaxPlaers(Number(event.target.value));
                            }}
                        />

                        <TextField
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            fullWidth
                            sx={{ width: 'calc(50% - 0.5rem)', mb: 3, mr: 2 }}
                            label='Total Price'
                            value={totalPrice}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setTotalPrice(Number(event.target.value));
                            }}
                        />
                        <TextField
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            fullWidth
                            sx={{ width: 'calc(50% - 0.5rem)', mb: 3 }}
                            label='Each Instance Price'
                            value={instancePrice}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setInstancePrice(Number(event.target.value));
                            }}
                        />

                        <TimePicker
                            sx={{ width: 'calc(50% - 0.5rem)', mb: 3, mr: 2 }}
                            label='Start Time'
                            value={startTime}
                            onChange={(newValue) => setStartTime(newValue)}
                        />
                        <TimePicker
                            sx={{ width: 'calc(50% - 0.5rem)', mb: 3 }}
                            label='End Time'
                            value={endTime}
                            onChange={(newValue) => setEndTime(newValue)}
                        />
                    </Grid>

                    <Occurances
                        clinic={clinic}
                        startDate={startDate}
                        repeatDays={repeatDays}
                        repeatQty={repeatQty}
                        onChangeStartDate={onChangeStartDate}
                        onChangeRepeatQty={setRepeatQty}
                        onChangeRepeatDays={setRepeatDays}
                    />
                </Box>
            )}
        </DrawerWithChangesDialogue>
    );
};

export default ConfigureClinicDrawer;
