import { DateTime } from 'luxon';
import { Court } from '../../../common/types/Court';
import { Facility } from '../../../common/types/Facility';
import {
    Clinic,
    ClinicInstance,
    EventInstance,
    ISODateStr,
    Reservation,
    ScheduleEventCategoryEnum,
} from '../../../common/types/ScheduleEvent';

export class ScheduleUtils {
    static getEmptyEvent(
        clientId: string,
        facilityId: string,
        courtId: string,
        startTime: ISODateStr,
        endTime: ISODateStr,
        maxPlayers: number
    ): Reservation {
        return new Reservation(
            clientId,
            facilityId,
            courtId,
            {
                start: startTime,
                end: endTime,
            },
            // ScheduleEventCategoryEnum.Reservation,
            'Open Court',
            [],
            [],
            [],
            maxPlayers,
            '2021-01-01',
            100
        );
    }

    static insertOpenEventsForFacilityCourts(
        scheduleEvents: EventInstance[],
        facility: Facility,
        courts: Court[]
    ) {
        const sortedEvents = scheduleEvents.sort((e1, e2) => {
            return DateTime.fromISO(e1.timeSlot.start).hour - DateTime.fromISO(e2.timeSlot.start).hour;
        });
        //todo: probably will figure out the date based on first event's date
        //since this method will be passed scheduled events from the component
        //and the component will fetch the events for the date chosen
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const fullSchedule: EventInstance[] = [];

        const startOfDay: DateTime = DateTime.fromFormat(facility.facilityOpenTime, 't');
        const endOfDay: DateTime = DateTime.fromFormat(facility.facilityCloseTime, 't');

        let currentStartTime = startOfDay;
        while (currentStartTime < endOfDay) {
            const eventsForFacility = ScheduleUtils.getScheduleEventForFacilityId(
                sortedEvents,
                facility.facilityId
            );
            facility.courtIds.forEach((courtId) => {
                const eventsForCourtAtFacility = ScheduleUtils.getScheduleEventsForCourtId(
                    eventsForFacility,
                    courtId
                );
                const eventsForTimeSlot = eventsForCourtAtFacility.filter(
                    (event: EventInstance) =>
                        DateTime.fromISO(event.timeSlot.start).hour === currentStartTime.hour
                );

                if (eventsForTimeSlot.length > 0) {
                    fullSchedule.push(...eventsForTimeSlot);
                } else {
                    const startTime = currentStartTime.toISO() || '';
                    const endTime = currentStartTime.plus({ hours: 1 }).toISO() || '';
                    const court = courts.find((court) => court.courtId === courtId);
                    fullSchedule.push(
                        ScheduleUtils.getEmptyEvent(
                            facility.clientId,
                            facility.facilityId,
                            courtId,
                            startTime,
                            endTime,
                            court?.maxPlayers || 4
                        )
                    );
                }
            });

            // currentStartTime.setHours(currentStartTime.getHours() + 1);
            currentStartTime = currentStartTime.plus({ hours: 1 });
        }

        return fullSchedule;
    }

    static getScheduleEventsMapByCourtId(scheduleEvents: EventInstance[]) {
        const scheduleEventsMap: { [key: string]: EventInstance[] } = {};
        const courtIds = scheduleEvents.reduce((acc: string[], event) => {
            acc.push(event.courtId);
            return acc;
        }, []);
        courtIds.forEach((courtId) => {
            scheduleEventsMap[courtId] = ScheduleUtils.getScheduleEventsForCourtId(scheduleEvents, courtId);
        });
        return scheduleEventsMap;
    }

    static getScheduleEventsMapByType(scheduleEvents: EventInstance[]) {
        const scheduleEventsMap: { [key: string]: EventInstance[] } = {};
        Object.values(ScheduleEventCategoryEnum).forEach((eventCategory) => {
            scheduleEventsMap[eventCategory] = ScheduleUtils.getScheduleEventsOfType(
                scheduleEvents,
                eventCategory
            );
        });
        return scheduleEventsMap;
    }

    static getScheduleEventForFacilityId(scheduleEvents: EventInstance[], facilityId: string) {
        return scheduleEvents.filter((event) => event.facilityId === facilityId);
    }

    static getScheduleEventsForCourtId(scheduleEvents: EventInstance[], courtId: string) {
        return scheduleEvents.filter((event) => event.courtId === courtId);
    }

    static getScheduleEventsForClientId(scheduleEvents: EventInstance[], clientId: string) {
        return scheduleEvents.filter((event) => event.clientId === clientId);
    }

    static getClinicsForClientId(clinics: Clinic[], clientId: string) {
        return clinics.filter((clinic) => clinic.clientId === clientId);
    }

    static getScheduleEventsOfType(
        scheduleEvents: EventInstance[],
        eventCategory: ScheduleEventCategoryEnum
    ) {
        return scheduleEvents.filter((event) => event.eventCategory === eventCategory);
    }

    static generateClinicInstances(
        clinic: Clinic,
        startDate: DateTime,
        repeatQty: number,
        repeatOnDays: string[]
    ) {
        const clinicInstances: ClinicInstance[] = [];

        const firstClinicInstance = ScheduleUtils.getInstanceForEachCourt(clinic, startDate);
        clinicInstances.push(...firstClinicInstance);
        //while not yet up to repeat qty
        //if not yet at end of repeat days list
        //  get the day of the week for the current repeat days index
        //  to add clinic instances for each court
        //if at the beginning of the repeat days index,
        //  go to the next week and get the date of the day of the week
        //  to add clinic instances for each court

        //repeatDays sort to actual order they occur in the week
        //they start in the order a user has clicked them, this puts it in correct order
        repeatOnDays.sort((day1, day2) => {
            return DateTime.fromFormat(day1, 'cccc').weekday - DateTime.fromFormat(day2, 'cccc').weekday;
        });
        let weekNum = 1;
        let dayIndex = repeatOnDays.indexOf(startDate.weekdayLong!) + 1;
        while (weekNum <= repeatQty) {
            if (dayIndex >= repeatOnDays.length) {
                dayIndex = 0;
                weekNum++;
            }
            if (weekNum <= repeatQty) {
                const prevInstance = clinicInstances[clinicInstances.length - 1];
                const dayForIndex = repeatOnDays[dayIndex];
                const dateOfNextInstance = ScheduleUtils.getDateOfNextInstance(prevInstance, dayForIndex);
                const clinicInstancesForNextDate = ScheduleUtils.getInstanceForEachCourt(
                    clinic,
                    dateOfNextInstance
                );
                clinicInstances.push(...clinicInstancesForNextDate);

                dayIndex++;
            }
        }
        return clinicInstances;
    }

    static getDateOfNextInstance(prevInstance: ClinicInstance, dayOfTheWeek: string): DateTime {
        const prevInstanceDate = DateTime.fromISO(prevInstance.timeSlot.start);
        let nextInstanceDate = prevInstanceDate;
        while (nextInstanceDate.weekdayLong !== dayOfTheWeek) {
            nextInstanceDate = nextInstanceDate.plus({ days: 1 });
        }
        //todo does this support clinics that last more than 1 hour?
        return nextInstanceDate;
    }

    static getInstanceForEachCourt(clinic: Clinic, startDate: DateTime) {
        const clinicInstances: ClinicInstance[] = [];
        clinic.courtIds.forEach((courtId: string) => {
            const startTimeWithRightDate = DateTime.fromObject({
                year: startDate.year,
                month: startDate.month,
                hour: DateTime.fromISO(clinic.timeSlot.start).hour,
            });
            const clinicInstance: ClinicInstance = {
                eventId: `${clinic.clinicId}-0`, //todo figure out how to deal with ids
                clientId: clinic.clientId,
                facilityId: clinic.facilityId,
                courtId: courtId,
                timeSlot: {
                    start: startTimeWithRightDate.toISO() || '',
                    end: startTimeWithRightDate.plus({ hours: 1 }).toISO() || '',
                },
                eventCategory: clinic.eventCategory,
                eventName: `${clinic.eventName} 1`,
                signedUpPlayers: clinic.signedUpPlayers,
                checkedInPlayers: [],
                paidPlayers: clinic.paidPlayers,
                maxPlayers: clinic.maxPlayers,
                releaseDate: clinic.releaseDate,

                parentClinicId: clinic.clinicId,
                clinicDescription: clinic.clinicDescription,
                clinicSport: clinic.clinicSport,
                instancePrice: clinic.instancePrice,
                instanceStaff: clinic.clinicStaff,
            };
            clinicInstances.push(clinicInstance);
        });
        return clinicInstances;
    }

    static getExpectedNumberOfInstancesToBeCreated(
        clinic: Clinic,
        startDate: DateTime,
        repeatQty: number,
        repeatOnDays: string[]
    ) {
        //todo looks like a bug in this code, or generally in the code that tells user how many instances/courts etc
        //try ptting 3 courts, only chekcing off today, and 1 repeat qty
        //youll see: "2 event instances" (wrong) and "".66666 clincis on 3 courts" (wrong)
        //should be 1 event instance and 1 clinic on 3 courts
        let expectedNumberOfInstances = 1;
        repeatOnDays.sort((day1, day2) => {
            return DateTime.fromFormat(day1, 'cccc').weekday - DateTime.fromFormat(day2, 'cccc').weekday;
        });
        let weekNum = 1;
        let dayIndex = repeatOnDays.indexOf(startDate.weekdayLong!) + 1;
        while (weekNum <= repeatQty) {
            if (dayIndex >= repeatOnDays.length) {
                dayIndex = 0;
                weekNum++;
            }
            if (weekNum <= repeatQty) {
                expectedNumberOfInstances++;
                dayIndex++;
            }
        }
        return expectedNumberOfInstances * clinic.courtIds.length;
    }

    static getOverlappingEvents(
        existingEvents: EventInstance[],
        newClinic: Clinic,
        newEvents: ClinicInstance[]
    ): EventInstance[] {
        const overlappingEvents: EventInstance[] = [];
        //figure out if any events exist in the spots generatedInstances will exist
        //todo compare entier time not just start time
        //todo remove from consideration any events that are apart of the existing clinic that they want to change
        //todo in general there needs to be a different between creating new clinic and eediting an existing one
        //and somehow cant edit existing if there are already people signed up
        //also need to allow people to sign up to entire series or just one instance
        const eventsOnSameCourts = existingEvents.filter((e) => {
            return newClinic.courtIds.includes(e.courtId);
        });
        const eventsOnSameCourtsAndSameTimes = eventsOnSameCourts.filter((e) => {
            return newEvents[0].timeSlot.start === e.timeSlot.start;
        });
        if (eventsOnSameCourtsAndSameTimes.length > 0) {
            newEvents.forEach((i) => {
                eventsOnSameCourtsAndSameTimes.forEach((e) => {
                    if (e.timeSlot.start === i.timeSlot.start && e.courtId === i.courtId) {
                        // console.log('Wanted to create this clinic instance based on the config:', i);
                        // console.log('But this event already exists at that date & time and on that court', e);
                        overlappingEvents.push(e);
                    }
                });
            });
        }

        return overlappingEvents;
    }
}
