import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../constants/QueryContstants';
import { AnyEvent } from '../../types/ScheduleEvent';

export const useUpdateEvent = (event: AnyEvent) => {
    const queryClient = useQueryClient();
    const {
        mutate: updateEvent,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (updatedEvent: AnyEvent) => {
            return fetch('https://jsonplaceholder.typicode.com/todos', {
                method: 'POST',
                body: JSON.stringify(updatedEvent),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_EVENTS, event.timeSlot.start],
            });
        },
    });

    return { updateEvent, isLoading };
};
