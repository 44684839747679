import { Button, Card, CardActions, CardContent } from '@mui/material';

import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { Clinic } from '../../../common/types/ScheduleEvent';
import { CourtUtils } from '../../../common/utils/CourtUtils';
import { DateUtils } from '../../../common/utils/DateUtils';
import ConfigureClinicDrawer from './ConfigureClinicDrawer';

interface ClinicGridCardProps {
    clinic: Clinic;
    onViewInstances: (clinic: Clinic) => void;
}

const ClinicGridCard = ({ clinic, onViewInstances }: ClinicGridCardProps) => {
    const [selected, setSelected] = useState<boolean>(false);
    const { courts } = useCourts(clinic.clientId);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    useEffect(() => {
        clinic = { ...clinic };
    }, [drawerOpen]);

    const courtNames = useMemo(() => {
        if (courts === undefined) return '';
        return CourtUtils.getCourtNames(clinic.courtIds, courts);
    }, [clinic.courtIds, courts]);

    function openDrawer() {
        setSelected(true);
        setDrawerOpen(true);
    }

    function onDrawerClose() {
        setSelected(false);
        setDrawerOpen(false);
    }

    function onSaveEdits(clinic: Clinic) {
        //todo save api call, then close drawer
        //show loading indicator
        //does doing a mutation on the react query facilities key update hook useFacilities being used in Configure.tssx?
        //if it does, great, if not, then we need to do a manual refetch
        //from post send back new list of facilities to see if it works
        //also need to delete the old clinic instances and create new ones
        //also, if they have people signed up already you cant delete it
        //probably should show user why we cant delete so they can go ahead and fix any issues manually
        // toggleDrawer();
    }

    return (
        <>
            <Card
                // onClick={() => onSelectClinic(clinic)}
                key={clinic.clinicId}
                sx={{
                    cursor: 'pointer',
                    border: selected ? '1px solid black' : '',
                    width: '304px',
                }}
            >
                <CardContent>
                    <Typography variant='h6'>{clinic.eventName}</Typography>
                    <Typography color='text.secondary'>Courts: {courtNames}</Typography>
                    <Typography color='text.secondary'>
                        Hours: {DateUtils.getDisplayTimeSlot(clinic.timeSlot.start, clinic.timeSlot.end)}
                    </Typography>
                    <Typography color='text.secondary'>Sport: {clinic.clinicSport}</Typography>
                    <Typography color='text.secondary'>
                        Staff: {clinic.clinicStaff.map((s) => `${s.firstName} ${s.lastName}`).join(', ')}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={openDrawer}>
                        Edit
                    </Button>
                    <Button size='small' onClick={() => onViewInstances(clinic)}>
                        See Classes
                    </Button>
                </CardActions>
            </Card>

            <ConfigureClinicDrawer
                clinic={clinic}
                onSave={onSaveEdits}
                drawerOpen={drawerOpen}
                onDrawerClose={onDrawerClose}
            />
        </>
    );
};

export default ClinicGridCard;
