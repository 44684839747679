import { useQuery } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Facility } from '../../../types/Facility';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useFacilities = (clientId: string): { facilities: Facility[]; isLoadingFacilities: boolean } => {
    const { data: facilities, isLoading: isLoadingFacilities } = useQuery({
        queryKey: [QueryConstants.CLIENT_FACILITIES],
        queryFn: () =>
            // fetch('https://jsonplaceholder.typicode.com/posts').then((res) => {
            //     return MOCK_FACILITIES.filter((facility) => facility.clientId === 'client-1');
            // }),
            fetch(`${CommonUtils.getBaseUrl()}/facilities/${clientId}`).then((res) => {
                return res.json();
            }),
    });
    return { facilities, isLoadingFacilities };
};
