import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useStaff } from '../../../../common/hooks/api/useStaff';
import { ProStaff } from '../../../../common/types/ProStaff';
import {
    Clinic,
    ClinicInstance,
    Lesson,
    ScheduleEventCategoryEnum,
} from '../../../../common/types/ScheduleEvent';

function onlyUnique(value: ProStaff, index: number, array: ProStaff[]) {
    return array.indexOf(value) === index;
}

interface StaffListProps {
    event: Clinic | ClinicInstance | Lesson;
    addNewStaff?: (staff: ProStaff[]) => void;
}
// todo: if were going to have a save and cancel button
// dont let clicking out be the same as cancel
// if were not going to have save and cancel buttons then it always saves when you make changes
//this is probably better option because most of the time theyre just going to be checking in players
const StaffList = ({ event, addNewStaff = () => {} }: StaffListProps) => {
    const { staff, isLoadingStaff } = useStaff('client-1');
    const [selectedStaffIds, setSelectedStaffIds] = useState<string[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<ProStaff[]>([]);

    useEffect(() => {
        if (event.eventCategory === ScheduleEventCategoryEnum.ClinicInstance) {
            setSelectedStaff((event as ClinicInstance).instanceStaff);
        } else if (event.eventCategory === ScheduleEventCategoryEnum.Clinic) {
            setSelectedStaff((event as Clinic).clinicStaff);
        } else if (event.eventCategory === ScheduleEventCategoryEnum.Lesson) {
            setSelectedStaff((event as Lesson).lessonStaff);
        }
    }, [event]);

    useEffect(() => {
        setSelectedStaffIds(selectedStaff.map((staff) => staff.proId));
    }, [selectedStaff]);

    function handleSelectStaff(event: SelectChangeEvent<string[]>) {
        const { value } = event.target;
        setSelectedStaffIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
        const staffFromIds: ProStaff[] = (staff || []).filter((pro) => value.includes(pro.proId));
        setSelectedStaff(staffFromIds);
    }

    function renderStaffName(selected: string[]) {
        const staffFromIds: ProStaff[] = (staff || []).filter((pro) => selected.includes(pro.proId));
        return staffFromIds.map((staff: ProStaff) => `${staff.firstName} ${staff.lastName}`).join(', ');
    }

    function handleAddStaff() {
        addNewStaff(selectedStaff.filter(onlyUnique));
    }

    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 3 }}>
                    <InputLabel shrink id='staff-multiple-checkbox-label'>
                        Select Staff
                    </InputLabel>
                    <Select
                        labelId='staff-multiple-checkbox-label'
                        id='staff-multiple-checkbox'
                        multiple
                        value={selectedStaffIds}
                        onChange={handleSelectStaff}
                        input={<OutlinedInput label='Select Staff' />}
                        renderValue={renderStaffName}
                    >
                        {(staff || []).map((staff: ProStaff) => (
                            <MenuItem key={staff.proId} value={staff.proId}>
                                <Checkbox
                                    onClick={handleAddStaff}
                                    checked={selectedStaff.indexOf(staff) > -1}
                                />
                                <ListItemText primary={`${staff.firstName} ${staff.lastName}`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
};

export default StaffList;
