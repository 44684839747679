import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Court } from '../../../types/Court';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useUpdateCourt = () => {
    const queryClient = useQueryClient();
    const {
        mutate: updateCourt,
        mutateAsync: updateCourtAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (updateCourt: Court) => {
            // return fetch('https://jsonplaceholder.typicode.com/todos', {
            //     method: 'PUT',
            //     body: JSON.stringify(updateCourt),
            // });
            return fetch(`${CommonUtils.getBaseUrl()}/courts/${updateCourt.courtId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateCourt),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_COURTS],
            });
        },
    });

    return { updateCourt, updateCourtAsync, isLoading };
};
