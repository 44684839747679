import { Box } from '@mui/material';
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiperCads } from '../../../common/hooks/useSwiperScards';
import { EventInstance } from '../../../common/types/ScheduleEvent';
import ScheduleSwiperEvent from './ScheduleSwiperEvent';

interface ScheduleSwiperProps {
    scheduleOfEvents: EventInstance[];
}

//todo: put the slide closest to the current time in current view. might be touch with scolling version
const ScheduleSwiper = ({ scheduleOfEvents }: ScheduleSwiperProps) => {
    const { getSlidesPerView, getCardWidth } = useSwiperCads();

    return (
        <Box>
            <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                slidesPerView={getSlidesPerView()}
                spaceBetween={8}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            >
                {scheduleOfEvents.map((event: EventInstance) => {
                    return (
                        <SwiperSlide key={`${event.eventId}-${event.courtId}`}>
                            <ScheduleSwiperEvent event={event} width={getCardWidth} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </Box>
    );
};

export default ScheduleSwiper;
