import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Facility } from '../../../types/Facility';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useUpdateFacility = () => {
    const queryClient = useQueryClient();
    const {
        mutate: updateFacility,
        mutateAsync: updateFacilityAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (updateFacility: Facility) => {
            // return fetch('https://jsonplaceholder.typicode.com/todos', {
            //     method: 'PUT',
            //     body: JSON.stringify(updateFacility),
            // });
            return fetch(`${CommonUtils.getBaseUrl()}/facilities/${updateFacility.facilityId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateFacility),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_FACILITIES],
            });
        },
    });

    return { updateFacility, updateFacilityAsync, isLoading };
};
