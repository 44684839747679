import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Facility } from '../../../types/Facility';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useCreateFacility = () => {
    const queryClient = useQueryClient();
    const {
        mutate: createFacility,
        mutateAsync: createFacilityAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (newFacility: Facility) => {
            // return fetch('https://jsonplaceholder.typicode.com/todos', {
            //     method: 'POST',
            //     body: JSON.stringify(newFacility),
            // });
            return fetch(`${CommonUtils.getBaseUrl()}/facilities`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFacility),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_FACILITIES],
            });
        },
    });

    return { createFacility, createFacilityAsync, isLoading };
};
