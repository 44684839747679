import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { Player } from '../../../../common/types/Player';

interface PaymentFormProps {
    customer: Player;
    processPayment: (player: Player) => void;
}

const PaymentForm = ({ customer, processPayment }: PaymentFormProps) => {
    //todo: this could be a modal instead, or some other experience
    //the issue is that in the clinic config screen, we have the same drawer
    //for editing the clinic
    //in that drawer, it makes sense to have a save button
    //and you probably want to have a similar user experience throughout
    //also, you want the ability to take a credit card reader payment, not just the form
    return (
        <Box
            className='fade-in'
            sx={{
                mt: 6,
                p: 1,
                '& .MuiTextField-root': { my: 1 },
            }}
        >
            <Stack direction='row' spacing={2}>
                <Typography variant='h5'>Payment Info</Typography>
                <Typography variant='body2' sx={{ ml: 1, pt: 1 }}>
                    ({customer.firstName} {customer.lastName})
                </Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 4 }} />
            <TextField
                required
                id='first-name'
                label='First Name'
                sx={{ width: 'calc(50% - 0.5rem)', mr: 1 }}
                defaultValue={customer.firstName}
            />
            <TextField
                required
                id='last-name'
                label='Last Name'
                sx={{ width: '50%' }}
                defaultValue={customer.lastName}
            />
            <TextField id='email-address' label='Email Address' sx={{ width: 'calc(50% - 0.5rem)', mr: 1 }} />
            <TextField id='phone-number' label='Phone Number' sx={{ width: '50%' }} />
            <TextField required id='card-number' label='Credit Card Number' fullWidth />
            <TextField required id='card-zip' label='Zip Code' sx={{ width: 'calc(50% - 0.5rem)', mr: 1 }} />
            <TextField required id='card-cvc' label='CVC' sx={{ width: '50%' }} />
            <Button
                variant='contained'
                sx={{ mt: 1, float: 'right' }}
                size='large'
                onClick={() => processPayment(customer)}
            >
                Make Payment
            </Button>
        </Box>
    );
};

export default PaymentForm;
