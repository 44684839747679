import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Court } from '../../../types/Court';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useCreateCourt = () => {
    const queryClient = useQueryClient();
    const {
        mutate: createCourt,
        mutateAsync: createCourtAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (newCourt: Court) => {
            return fetch(`${CommonUtils.getBaseUrl()}/courts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCourt),
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_FACILITIES],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_COURTS],
            });
        },
    });

    return { createCourt, createCourtAsync, isLoading };
};
