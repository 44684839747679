import { useQuery } from '@tanstack/react-query';
import { ScheduleUtils } from '../../../features/Dashboard/Schedule/ScheduleUtils';
import { MOCK_CLINICS } from '../../../mockData/mockEvents';
import { QueryConstants } from '../../constants/QueryContstants';

export const useClinics = (clientId: string, isoDateString: string | null) => {
    const { data: clinics, isLoading: isLoadingClinics } = useQuery({
        queryKey: [QueryConstants.CLIENT_CLINICS, isoDateString],
        queryFn: () =>
            fetch('https://jsonplaceholder.typicode.com/photos').then((res) => {
                return ScheduleUtils.getClinicsForClientId(MOCK_CLINICS, clientId);
                // return res.json();
            }),
        enabled: !!isoDateString,
    });
    return { clinics, isLoadingClinics };
};
