import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Facility } from '../../../../common/types/Facility';
import ConfigureFacilityDrawer from './ConfigureFacilityDrawer';

const AddFacilityGridCard = () => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const facility: Facility = {
        clientId: 'client-1', //todo: stop hardocding clinet id (multiple places, need a solution)
        facilityId: '',
        facilityName: '',
        facilityOpenTime: '8:00 AM',
        facilityCloseTime: '11:00 PM',
        courtIds: [],
    };

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <>
            <Card
                key={'add-facility'}
                sx={{
                    height: '159px',
                    width: '240px',
                }}
            >
                <CardContent>
                    <Typography variant='h6'>Add New Facility</Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={toggleDrawer}>
                        <AddIcon />
                    </Button>
                </CardActions>
            </Card>
            <ConfigureFacilityDrawer
                facility={facility}
                drawerOpen={drawerOpen}
                onDrawerClose={toggleDrawer}
            />
        </>
    );
};

export default AddFacilityGridCard;
