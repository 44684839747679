import { Button, Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { ClinicInstance } from '../../../common/types/ScheduleEvent';
import { CourtUtils } from '../../../common/utils/CourtUtils';
import { DateUtils } from '../../../common/utils/DateUtils';
import EventDetailsDrawer from '../Schedule/EventDetails/EventDetailsDrawer';

interface ClinicInstanceGridCardProps {
    clinicInstance: ClinicInstance;
}

const ClinicInstanceGridCard = ({ clinicInstance }: ClinicInstanceGridCardProps) => {
    const { courts } = useCourts(clinicInstance.clientId);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <>
            <Card
                key={clinicInstance.eventId}
                sx={{
                    width: '240px',
                }}
            >
                <CardContent>
                    <Typography variant='h6'>{clinicInstance.eventName}</Typography>
                    <Typography color='text.secondary'>
                        Court: {courts && CourtUtils.getCourtName(clinicInstance.courtId, courts)}
                    </Typography>
                    <Typography color='text.secondary'>
                        {DateUtils.getSlashesDate(clinicInstance.timeSlot.start)}
                    </Typography>
                    <Typography
                        variant='overline'
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        color='text.secondary'
                    >
                        {DateUtils.getDisplayTimeSlot(
                            clinicInstance.timeSlot.start,
                            clinicInstance.timeSlot.end
                        )}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={toggleDrawer}>
                        Edit
                    </Button>
                </CardActions>
            </Card>
            <EventDetailsDrawer event={clinicInstance} drawerOpen={drawerOpen} onDrawerClose={toggleDrawer} />
        </>
    );
};

export default ClinicInstanceGridCard;
