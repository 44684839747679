import { Player } from '../common/types/Player';

export const MOCK_PLAYERS: Player[] = [
    {
        playerId: 'p1',
        firstName: 'John',
        lastName: 'Doe',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p2',
        firstName: 'Jane',
        lastName: 'Doe',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p3',
        firstName: 'John',
        lastName: 'Smith',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p4',
        firstName: 'Jane',
        lastName: 'Smith',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p5',
        firstName: 'John',
        lastName: 'Doe5',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p6',
        firstName: 'Jane',
        lastName: 'Doe6',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p7',
        firstName: 'John',
        lastName: 'Smith7',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
    {
        playerId: 'p8',
        firstName: 'Jane',
        lastName: 'Smith8',
        phoneNumber: '123-456-7890',
        email: '',
        paymentIds: [],
        eventIds: [],
    },
];
