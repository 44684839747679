import { createContext, useReducer } from 'react';
import { UserType } from '../types/User';

interface AuthContextInterface {
    user?: UserType;
    dispatch?: any;
}
export const AuthContext = createContext<AuthContextInterface>({});

export const authReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'LOGIN':
            return { user: action.payload };
        case 'LOGOUT':
            return { user: null };
        default:
            return state;
    }
};

export const AuthContextProvider = ({ children }: any) => {
    const [authState, dispatch] = useReducer(authReducer, { user: null });

    return <AuthContext.Provider value={{ ...authState, dispatch }}>{children}</AuthContext.Provider>;
};
