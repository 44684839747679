import { Route, Routes } from 'react-router-dom';
import { CallbackPage } from '../common/components/CallbackPage';
import Login from '../common/components/Login';
import Clinics from './Dashboard/Clinics/Clinics';
import Configure from './Dashboard/Configure/Configure';
import DashboardContainer from './Dashboard/DashboardContainer';
import Lessons from './Dashboard/Lessons';
import Payments from './Dashboard/Payments';
import Pros from './Dashboard/Pros';
import Schedule from './Dashboard/Schedule/Schedule';
import Summary from './Dashboard/Summary/Summary';
import HomepageContainer from './Homepage/HomepageContainer';

function MainRoutes() {
    return (
        <Routes>
            <Route path='/' element={<HomepageContainer />} />
            <Route path='/dashboard' element={<DashboardContainer />}>
                <Route index element={<Summary />} />
                <Route path='schedule' element={<Schedule />} />
                <Route path='clinics' element={<Clinics />} />
                <Route path='lessons' element={<Lessons />} />
                <Route path='pros' element={<Pros />} />
                <Route path='configure' element={<Configure />} />
                <Route path='payments' element={<Payments />} />
            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Login forSignup={true} />} />
            <Route path='/callback' element={<CallbackPage />} />
            {/* todo implement a not found page/error with * path */}
        </Routes>
    );
}

export default MainRoutes;
