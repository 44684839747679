import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Court, CourtLocationEnum, CourtSportEnum, CourtSurfaceEnum } from '../../../../common/types/Court';
import ConfigureCourtDrawer from './ConfigureCourtDrawer';

interface AddCourtGridCardProps {
    facilityId: string;
}

const AddCourtGridCard = ({ facilityId }: AddCourtGridCardProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    //todo move to a constants or something like that for default configs
    const court: Court = {
        clientId: 'client-1',
        facilityId,
        courtId: '',
        courtName: '',
        sport: CourtSportEnum.Tennis,
        courtLocation: CourtLocationEnum.Indoor,
        courtSurface: CourtSurfaceEnum.Hard,
        maxPlayers: 4,
    };

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <>
            <Card
                key={'add-court'}
                sx={{
                    height: '159px',
                    width: '240px',
                }}
            >
                <CardContent>
                    <Typography variant='h6'>Add New Court</Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={toggleDrawer}>
                        <AddIcon />
                    </Button>
                </CardActions>
            </Card>
            <ConfigureCourtDrawer court={court} drawerOpen={drawerOpen} onDrawerClose={toggleDrawer} />
        </>
    );
};

export default AddCourtGridCard;
