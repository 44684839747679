import { CardContent, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { EventInstance } from '../../../common/types/ScheduleEvent';
import { CourtUtils } from '../../../common/utils/CourtUtils';
import { DateUtils } from '../../../common/utils/DateUtils';

interface ScheduleEventCardContentProps {
    event: EventInstance;
}

const ScheduleEventCardContent = ({ event }: ScheduleEventCardContentProps) => {
    const { courts } = useCourts(event.clientId);
    const courtNames = useMemo(() => {
        if (courts === undefined) return '';
        return CourtUtils.getCourtNames([event.courtId], courts);
    }, [event.courtId, courts]);

    return (
        <CardContent sx={{ pt: 1 }}>
            <Stack direction='row' justifyContent='space-between' spacing={1} mb={1}>
                <Typography
                    variant='overline'
                    noWrap
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    color='text.secondary'
                >
                    {DateUtils.getDisplayTimeSlot(event.timeSlot.start, event.timeSlot.end)}
                </Typography>
                <Typography
                    variant='overline'
                    sx={{ overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis' }}
                    color='text.secondary'
                >
                    {event.eventCategory}
                </Typography>
            </Stack>
            <Typography variant='h6'>{event.eventName}</Typography>
            <Tooltip title={courtNames}>
                <Typography
                    color='text.secondary'
                    noWrap
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {courtNames}
                </Typography>
            </Tooltip>
            <Typography color='text.secondary'>Players: {event.signedUpPlayers.length}</Typography>
        </CardContent>
    );
};

export default ScheduleEventCardContent;
