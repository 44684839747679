import { Box, Grid } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { Court } from '../../../../common/types/Court';
import AddCourtGridCard from './AddCourtGridCard';
import CourtGridCard from './CourtGridCard';

interface CourtCardGridProps {
    facilityId: string;
    courts: Court[];
}

const CourtCardGrid = ({ facilityId, courts }: CourtCardGridProps) => {
    return (
        <Box m={2} mb={6}>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent='left' spacing={2}>
                        {courts.map((court: Court) => (
                            <Grid key={court.courtId} item>
                                <CourtGridCard court={court} />
                            </Grid>
                        ))}
                        <Grid key={'add-court'} item>
                            <AddCourtGridCard facilityId={facilityId} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CourtCardGrid;
