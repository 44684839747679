import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDeleteFacility } from '../../../../common/hooks/api/facilities/useDeleteFacility';
import { Facility } from '../../../../common/types/Facility';
import ConfigureFacilityDrawer from './ConfigureFacilityDrawer';

interface FacilityGridCardProps {
    facility: Facility;
    selected: boolean;
    onSelectFacility: (facility?: Facility) => void;
}

const FacilityGridCard = ({ facility, selected, onSelectFacility }: FacilityGridCardProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
    const { deleteFacilityAsync, isLoading } = useDeleteFacility();

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }
    function toggleDialogue() {
        setDialogueOpen(!dialogueOpen);
    }

    function onDeleteFacility() {
        deleteFacilityAsync(facility.facilityId);
        toggleDialogue();
        onSelectFacility();
    }

    return (
        <>
            <Card
                onClick={() => onSelectFacility(facility)}
                key={facility.facilityId}
                sx={{
                    cursor: 'pointer',
                    border: selected ? '1px solid black' : '',
                    width: '240px',
                }}
            >
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                <CardContent>
                    <Typography variant='h6'>{facility.facilityName}</Typography>
                    <Typography color='text.secondary'>Courts: {facility.courtIds.length}</Typography>
                    <Typography color='text.secondary'>
                        Hours: {facility.facilityOpenTime} - {facility.facilityCloseTime}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={toggleDrawer}>
                        Edit
                    </Button>
                    <Button size='small' onClick={toggleDialogue}>
                        Delete
                    </Button>
                </CardActions>
            </Card>
            <ConfigureFacilityDrawer
                facility={facility}
                drawerOpen={drawerOpen}
                onDrawerClose={toggleDrawer}
            />
            <Dialog open={dialogueOpen}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete this facility and any courts associated with it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteFacility}>Delete</Button>
                    <Button onClick={toggleDialogue} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FacilityGridCard;
