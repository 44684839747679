import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import { Card, CardContent, Divider, Stack, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CardTitle } from './CardTitle';

const iconStyle = {
    height: '24px',
    width: '24px',
    color: 'text.secondary',
    margin: 'auto !important',
};

const EventsMetricsCards = () => {
    const isLargeScreen = useMediaQuery('(min-width: 1400px)');
    return (
        <Stack direction={isLargeScreen ? 'column' : 'row'} spacing={3} mb={3}>
            <Card sx={{ flexGrow: 1 }}>
                <CardTitle title='Clinics' />
                <CardContent>
                    <Stack direction='row' spacing={3} mb={2} justifyContent='center' alignItems='center'>
                        <Typography variant='h3' component='div'>
                            5
                        </Typography>
                        <Stack spacing={0.5}>
                            <Typography color='text.secondary'>Types of Clinics</Typography>
                            <GroupsIcon sx={iconStyle} />
                        </Stack>
                    </Stack>
                    <Divider sx={{ mb: 2 }} />
                    <Stack spacing={1}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Signed Up Today</Typography>
                            <Typography color='text.secondary'>12</Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Signed Up All Time</Typography>
                            <Typography color='text.secondary'>123</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>

            <Card sx={{ flexGrow: 1 }}>
                <CardTitle title='Lessons' />
                <CardContent>
                    <Stack direction='row' spacing={3} mb={2} justifyContent='center' alignItems='center'>
                        <Typography variant='h3' component='div'>
                            3
                        </Typography>
                        <Stack spacing={0.5}>
                            <Typography color='text.secondary'>Pros Available Today</Typography>
                            <GroupIcon sx={iconStyle} />
                        </Stack>
                    </Stack>
                    <Divider sx={{ mb: 2 }} />
                    <Stack spacing={1}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Signed Up Today</Typography>
                            <Typography color='text.secondary'>12</Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Signed Up All Time</Typography>
                            <Typography color='text.secondary'>123</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>

            <Card sx={{ flexGrow: 1 }}>
                <CardTitle title='Reservations' />
                <CardContent>
                    <Stack direction='row' spacing={3} mb={2} justifyContent='center' alignItems='center'>
                        <Typography variant='h3' component='div'>
                            123
                        </Typography>
                        <Stack spacing={0.5}>
                            <Typography color='text.secondary'>Hours Available Today</Typography>
                        </Stack>
                    </Stack>
                    <Divider sx={{ mb: 2 }} />
                    <Stack spacing={1}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Hours Reserved Today</Typography>
                            <Typography color='text.secondary'>12</Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                            <Typography color='text.secondary'>Hours Reserved All Time</Typography>
                            <Typography color='text.secondary'>123</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};

export default EventsMetricsCards;
