import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { useFacilities } from '../../../common/hooks/api/facilities/useFacilities';
import { useEvents } from '../../../common/hooks/api/useEvents';
import { Facility } from '../../../common/types/Facility';
import { EventInstance, ScheduleEventCategoryEnum } from '../../../common/types/ScheduleEvent';
import CourtsSchedulesList from './CourtsSchedulesList';
import EventCategorySelector from './EventCategorySelector';
import { ScheduleUtils } from './ScheduleUtils';

//todo: make responsive //useMediaQuery on the container //swiper has responsive abilities
//todo: put the slide closest to the current time in current view. might be touch with scolling version
//todo: allow half hour start times
//todo: allow events to be multi-hour
const Schedule = () => {
    const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
    const [selectedFacility, setSelectedFacility] = useState<Facility>();
    const [selectedEventCategory, setSelectedEventCategory] = useState<'all' | ScheduleEventCategoryEnum>(
        'all'
    );

    const { events, isLoadingEvents } = useEvents('client-1', selectedDate.toISODate());
    const { facilities, isLoadingFacilities } = useFacilities('client-1');
    const { courts, isLoadingCourts } = useCourts('client-1');

    const [fullScheduleByCourt, setFullScheduleByCourt] = useState<{ [key: string]: EventInstance[] }>({});
    const [clientFacilityEvents, setClientFacilityEvents] = useState<EventInstance[]>([]);
    const [fullScheduleWithOpeningsForFacility, setFullScheduleWithOpeningsForFacility] = useState<
        EventInstance[]
    >([]);

    useEffect(() => {
        if (facilities && facilities.length > 0) {
            setSelectedFacility(facilities[0]);
        }
    }, [facilities]);

    useEffect(() => {
        if (events && events.length > 0 && selectedFacility) {
            const selectedFacilityEvents = ScheduleUtils.getScheduleEventForFacilityId(
                events,
                selectedFacility.facilityId
            );
            setClientFacilityEvents(selectedFacilityEvents);

            setFullScheduleWithOpeningsForFacility(
                ScheduleUtils.insertOpenEventsForFacilityCourts(
                    selectedFacilityEvents,
                    selectedFacility,
                    courts || []
                )
            );
        }
    }, [selectedFacility, events, clientFacilityEvents]);

    useEffect(() => {
        if (fullScheduleWithOpeningsForFacility.length > 0) {
            if (selectedEventCategory === 'all') {
                setFullScheduleByCourt(
                    ScheduleUtils.getScheduleEventsMapByCourtId(fullScheduleWithOpeningsForFacility)
                );
            } else {
                const eventsOfType = ScheduleUtils.getScheduleEventsOfType(
                    fullScheduleWithOpeningsForFacility,
                    selectedEventCategory
                );
                setFullScheduleByCourt(ScheduleUtils.getScheduleEventsMapByCourtId(eventsOfType));
            }
        }
    }, [selectedEventCategory, fullScheduleWithOpeningsForFacility]);

    const handleFacilityChange = (changeEvent: SelectChangeEvent<string>) => {
        const facility = facilities!.find((facility) => {
            return facility.facilityId === changeEvent.target.value;
        });
        if (facility) {
            setSelectedFacility(facility);
            setSelectedEventCategory('all');
        }
    };

    const handleDateChange = (newDate: DateTime | null) => {
        if (newDate) {
            setSelectedDate(newDate);
        }
    };

    //todo: find loading spinner
    return (
        <div className='dashboard-schedule'>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>User stories to accomplish here:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        This is the main base of operations during the day for receptionists. It is for basic
                        operations of existing events.
                    </Typography>
                    <Typography paragraph>
                        This is not for editing more granular details of events. For example, if a user wants
                        to edit a clinic's description and the repeat occurrances, they should do it in the
                        Configure Clinics view.
                    </Typography>
                    <Typography paragraph>1. See current schedule for each court at each facility</Typography>
                    <Typography paragraph>2. See existing events and open time slots</Typography>
                    <Typography paragraph>3. Filter view to a specific date</Typography>
                    <Typography paragraph>4. Filter view to see specific event types</Typography>
                    <Typography paragraph>5. View/edit each events details: staff</Typography>
                    <Typography paragraph>6. View/edit each events details: players</Typography>
                    <Typography paragraph>7. Check in players to events</Typography>
                    <Typography paragraph>8. Pay for players for events</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        1. X - A select for facility and a swiper for each court at facility selected.
                    </Typography>
                    <Typography paragraph>
                        2. X - A swiper card for each event/time including open timeslots
                    </Typography>
                    <Typography paragraph>
                        3. X - A datepicker to show events on that date, default to today
                    </Typography>
                    <Typography paragraph>4. X - A control to filter by event type</Typography>
                    <Typography paragraph>
                        5. ? - Click card to edit event details in drawer: staff
                    </Typography>
                    <Typography paragraph>
                        6. X - Click card to edit event details in drawer: players
                    </Typography>
                    <Typography paragraph>
                        7. X - Click card to edit event details in drawer: check in players
                    </Typography>
                    <Typography paragraph>
                        8. X - Click card to edit event details in drawer: payment info
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Typography variant='h3' gutterBottom>
                Schedule
            </Typography>

            <Divider sx={{ mb: 2 }} />

            <Box mb={2}>
                <FormControl>
                    <InputLabel>Facility</InputLabel>
                    {!isLoadingFacilities && facilities && selectedFacility ? (
                        <Select
                            value={selectedFacility.facilityId}
                            label='Facility'
                            onChange={handleFacilityChange}
                        >
                            {facilities?.map((facility) => {
                                return (
                                    <MenuItem key={facility.facilityId} value={facility.facilityId}>
                                        {facility.facilityName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    ) : (
                        <Select value={''} label='Facility' onChange={handleFacilityChange}>
                            <MenuItem key={''} value={''}>
                                No facilities found
                            </MenuItem>
                        </Select>
                    )}
                </FormControl>

                <DatePicker
                    sx={{ display: 'inline-block', ml: 2 }}
                    label='Date'
                    value={selectedDate}
                    onChange={(newDate) => handleDateChange(newDate)}
                />

                <EventCategorySelector {...{ selectedEventCategory, setSelectedEventCategory }} />
            </Box>

            {/* todo: better handle api response errors */}
            {!isLoadingFacilities && !facilities && 'No facilities found. Try hitting refresh.'}
            {!isLoadingEvents && !events && 'No events found. Try hitting refresh'}

            {!isLoadingEvents &&
                !isLoadingFacilities &&
                !isLoadingCourts &&
                selectedFacility &&
                facilities &&
                events &&
                courts && (
                    <CourtsSchedulesList
                        isLoading={isLoadingEvents || isLoadingFacilities || isLoadingCourts}
                        fullScheduleByCourt={fullScheduleByCourt}
                        clientCourts={courts}
                    />
                )}
        </div>
    );
};

export default Schedule;
