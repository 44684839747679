export const SalesByEventTypeConstants = {
    OPTIONS: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                border: {
                    display: false,
                },
                grid: {
                    color: function (context: any) {
                        return 'transparent';
                    },
                },
            },
            y: {
                border: {
                    display: false,
                },
                grid: {
                    drawTicks: true,
                    tickColor: '#ffffff',
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
    },
};

export class SalesByEventtypeUtils {
    //todo: api to return this aggregated data on a daily interval (most granular)
    //todo: ui can aggregate it for the selected timeframe to be less granular
    static getChartData = () => {
        return {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Clinics',
                    data: [10, 20, 30, 50, 50, 60, 70],
                    backgroundColor: 'rgba(20,163,127, 0.75)',
                },
                {
                    label: 'Lessons',
                    data: [15, 15, 30, 20, 50, 40, 50],
                    backgroundColor: 'rgba(29,233,182, 0.75)',
                },
                {
                    label: 'Courts Reservations',
                    data: [30, 30, 20, 30, 30, 40, 40],
                    backgroundColor: 'rgba(51,160,149, 0.75)',
                },
            ],
        };
    };
}
