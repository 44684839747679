import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useEvents } from '../../../common/hooks/api/useEvents';
import { EventInstance } from '../../../common/types/ScheduleEvent';
import ScheduleEventCardContent from '../Schedule/ScheduleEventCardContent';
import { CardTitle } from './CardTitle';

interface HappeningSoonProps {
    onClickDetails: (event: EventInstance) => void;
}

const today = new Date().toISOString();

export const HappeningSoon = ({ onClickDetails }: HappeningSoonProps) => {
    const { events } = useEvents('client-1', today);
    const upcomingEvents: EventInstance[] = useMemo(() => {
        if (events) {
            return events.filter((e: EventInstance) => {
                const now = new Date();
                const currHours = now.getHours();
                return (
                    DateTime.fromISO(e.timeSlot.start).hour > currHours &&
                    DateTime.fromISO(e.timeSlot.start).hour < currHours + 2
                );
            });
        }
        return [];
    }, [events]);

    return (
        <Card sx={{ mb: 3 }}>
            <CardTitle title='Happening Soon' />
            <Box sx={{ maxHeight: 'calc(100vh - 8rem)', overflow: 'scroll' }}>
                <Stack spacing={0}>
                    {upcomingEvents?.length === 0 && (
                        <Card
                            sx={{
                                minWidth: 275,
                                borderRadius: 0,
                                border: 'none',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        >
                            <CardContent>
                                <Typography variant='body1' textAlign={'center'}>
                                    No events happening in next 2 hours
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    {upcomingEvents?.map((event) => (
                        <Card
                            key={event.eventId}
                            onClick={() => onClickDetails(event)}
                            sx={{
                                minWidth: 275,
                                borderRadius: 0,
                                border: 'none',
                                borderBottom: '1px solid #e0e0e0',
                            }}
                        >
                            <ScheduleEventCardContent event={event} />
                            <CardActions>
                                <Button size='small'>Details</Button>
                            </CardActions>
                        </Card>
                    ))}
                </Stack>
            </Box>
        </Card>
    );
};
