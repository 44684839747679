import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Button,
    Checkbox,
    FilledInput,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { UserType } from '../types/User';

interface LoginProps {
    forSignup?: boolean;
}

const Login = ({ forSignup }: LoginProps) => {
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { user, dispatch } = useAuthContext();
    const navigate = useNavigate();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (forSignup) {
            //request should return new user to set context
            const mockResponse: UserType = {
                firstName: 'dude from signup respnose',
                lastName: 'sweet from signup response',
            };
            dispatch({ type: 'LOGIN', payload: mockResponse });
            navigate('/dashboard');
        } else {
            //request should return existing user to set context
            const mockResponse: UserType = {
                firstName: 'dude from login respnose',
                lastName: 'sweet from login response',
            };
            dispatch({ type: 'LOGIN', payload: mockResponse });
            navigate('/dashboard');
        }
    };

    return (
        <Box
            component='form'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
        >
            <Stack>
                <TextField
                    required
                    id='filled-required'
                    label='Username'
                    variant='filled'
                    fullWidth
                    autoFocus
                    sx={{ mt: 3 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setUsername(event.target.value);
                    }}
                />
                {forSignup && (
                    <TextField
                        required
                        id='filled-required'
                        label='First Name'
                        variant='filled'
                        fullWidth
                        sx={{ mt: 3 }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstName(event.target.value);
                        }}
                    />
                )}
                <FormControl variant='filled' required fullWidth sx={{ mt: 3 }}>
                    <InputLabel htmlFor='filled-adornment-password'>Password</InputLabel>
                    <FilledInput
                        id='filled-adornment-password'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge='end'
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {!forSignup && (
                    <FormControlLabel
                        control={<Checkbox value='remember' color='primary' />}
                        label='Remember me'
                        sx={{ mt: 3 }}
                    />
                )}
                <Button variant='contained' type='submit' fullWidth sx={{ mt: 3, mb: 2 }}>
                    {forSignup ? 'Sign Up' : 'Log In'}
                </Button>
            </Stack>
        </Box>
    );
};

export default Login;
