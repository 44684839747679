import { Box, Card, CardContent, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useState } from 'react';

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CardTitle } from './CardTitle';
import { SalesByEventTypeConstants, SalesByEventtypeUtils } from './SalesByEventTypeConstants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalesByEventType = () => {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<
        'week' | 'month' | 'threeMonths' | 'sixMonths' | 'year'
    >('month');

    function handleTimeFrameChange(
        event: React.MouseEvent<HTMLElement>,
        timeFrame: 'week' | 'month' | 'threeMonths' | 'sixMonths' | 'year'
    ) {
        setSelectedTimeFrame(timeFrame);
    }

    const chartData = SalesByEventtypeUtils.getChartData();

    return (
        <>
            <Card sx={{ height: '500px' }}>
                <CardTitle title='Sales by Event Type' />
                <CardContent
                    sx={{
                        height: '310px',
                        // width: 'calc(100% - 1.5rem)',
                        width: '100%',
                    }}
                >
                    <ToggleButtonGroup
                        color='primary'
                        value={selectedTimeFrame}
                        exclusive
                        onChange={handleTimeFrameChange}
                        sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}
                    >
                        <ToggleButton value='week'>Week</ToggleButton>
                        <ToggleButton value='month'>Month</ToggleButton>
                        <ToggleButton value='threeMonths'>3 Months</ToggleButton>
                        <ToggleButton value='sixMonths'>6 Months</ToggleButton>
                        <ToggleButton value='year'>Year</ToggleButton>
                    </ToggleButtonGroup>

                    <Box sx={{ height: '20px', width: '100%', mb: 6 }}>
                        <Stack direction='row' spacing={1} justifyContent='space-between'>
                            <Stack>
                                <Typography variant='overline' lineHeight={1.5}>
                                    Net Sales
                                </Typography>
                                <Typography variant='overline' lineHeight={1.5}>
                                    Start Date - End Date
                                </Typography>
                            </Stack>
                            <Typography variant='h5'>$1,234</Typography>
                        </Stack>
                    </Box>

                    <Bar options={SalesByEventTypeConstants.OPTIONS} data={chartData} />
                </CardContent>
            </Card>
        </>
    );
};

export default SalesByEventType;
