import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useDeleteFacility = () => {
    const queryClient = useQueryClient();
    const {
        mutate: deleteFacility,
        mutateAsync: deleteFacilityAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (facilityId: string) => {
            // return fetch('https://jsonplaceholder.typicode.com/todos', {
            //     method: 'DELETE'
            // });
            return fetch(`${CommonUtils.getBaseUrl()}/facilities/${facilityId}`, {
                method: 'DELETE',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_FACILITIES],
            });
        },
    });

    return { deleteFacility, deleteFacilityAsync, isLoading };
};
