import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const Pros = () => {
    return (
        <div className='dashboard-pros'>
            <Typography variant='h3' gutterBottom>
                Pros
            </Typography>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>User stories to accomplish here:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        1. View/edit staff details: name, bio, contact info, photo
                    </Typography>
                    <Typography paragraph>2. View/edit staff details: calendar shifts</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AccordionDetails>
                        <Typography paragraph>
                            1. An overflowing grid of cards: a card for each staff
                        </Typography>
                        <Typography paragraph>2. Click to edit details in drawer</Typography>
                        <Typography paragraph>3. Click to view event assignments below</Typography>
                        <Typography paragraph>4. Click add new card to create new staff</Typography>
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>

            <Divider sx={{ mb: 2 }} />
        </div>
    );
};

export default Pros;
