import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Player } from '../../../../common/types/Player';
import { AnyEvent, EventInstance } from '../../../../common/types/ScheduleEvent';

interface PlayersListProps {
    event: AnyEvent;
    updateSignedUpPlayers: (players: Player[]) => void;
    updateCheckedInPlayers: (players: Player[]) => void;
    updatePaidPlayers: (players: Player[]) => void;
    onClickPay: (player: Player) => void;
}

const PlayersList = ({
    event,
    updateSignedUpPlayers,
    updateCheckedInPlayers,
    updatePaidPlayers,
    onClickPay,
}: PlayersListProps) => {
    const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
    const [newPlayerFirst, setNewPlayerFirst] = useState<string>('');
    const [newPlayerLast, setNewPlayerLast] = useState<string>('');

    function handleCloseDialogue() {
        setNewPlayerFirst('');
        setNewPlayerLast('');
        setDialogueOpen(false);
    }

    function handleAddPlayer() {
        //todo: unique id
        //todo: selector to add existing player that has account
        const eventCopy = { ...event };
        eventCopy.signedUpPlayers.push({
            playerId: 'player-3', //todo stop hardcoding
            firstName: newPlayerFirst,
            lastName: newPlayerLast,
            eventIds: [],
            paymentIds: [],
            phoneNumber: '',
            email: '',
        });
        updateSignedUpPlayers(eventCopy.signedUpPlayers);
        setNewPlayerFirst('');
        setNewPlayerLast('');
        setDialogueOpen(false);
    }

    function removePlayer(player: Player) {
        const eventCopy = { ...event } as EventInstance;
        eventCopy.signedUpPlayers = eventCopy.signedUpPlayers.filter(
            (signedUpPlayer) => signedUpPlayer.playerId !== player.playerId
        );
        updateSignedUpPlayers(eventCopy.signedUpPlayers);
    }

    function handleCheckIn(player: Player) {
        //todo clinic (non-instance) do not have checked in players so deal with that somehow
        //people can only check in to a single event instance and a clinic is not an event instance
        const eventCopy = { ...event } as EventInstance;
        if (playerCheckedIn(player)) {
            eventCopy.checkedInPlayers = eventCopy.checkedInPlayers.filter(
                (checkedInPlayer) => checkedInPlayer.playerId !== player.playerId
            );
        } else {
            eventCopy.checkedInPlayers.push(player);
        }
        updateCheckedInPlayers(eventCopy.checkedInPlayers);
    }

    function playerPaid(player: Player): boolean {
        return event.paidPlayers.includes(player);
    }
    function playerCheckedIn(player: Player): boolean {
        return (event as EventInstance).checkedInPlayers.includes(player);
    }

    return (
        <>
            <Grid item xs={12} mt={6}>
                <Typography variant='h5' gutterBottom>
                    Players
                    <Button variant='outlined' sx={{ float: 'right' }} onClick={() => setDialogueOpen(true)}>
                        + Add Player
                    </Button>
                    <Typography variant='body1' sx={{ float: 'right', mr: 3, pt: 1 }}>
                        {event.signedUpPlayers.length} Players
                    </Typography>
                </Typography>

                <Divider sx={{ mt: 1, mb: 1 }} />
            </Grid>
            {event.signedUpPlayers.length > 0 &&
                event.signedUpPlayers.map((player: Player) => (
                    <React.Fragment key={`${player.firstName}-${player.lastName}`}>
                        <Grid item xs={6} mb={1}>
                            <Typography key={player.playerId} variant='body1' pt={1}>
                                {player.firstName} {player.lastName}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sx={{ textAlign: 'left' }}>
                            {playerPaid(player) ? (
                                <Button variant='text' disabled>
                                    Paid
                                </Button>
                            ) : (
                                <Button variant='outlined' onClick={() => onClickPay(player)}>
                                    Pay
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={3} sx={{ textAlign: 'center' }}>
                            <FormControlLabel
                                sx={{ margin: 'auto' }}
                                control={
                                    <Checkbox
                                        checked={playerCheckedIn(player)}
                                        onChange={() => handleCheckIn(player)}
                                    />
                                }
                                label={playerCheckedIn(player) ? 'Checked In' : 'Check In'}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: 'right' }}>
                            <Button size='small' onClick={() => removePlayer(player)}>
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </React.Fragment>
                ))}

            {event.signedUpPlayers.length === 0 && (
                <Grid item xs={12} mb={6}>
                    <Typography variant='body1' pt={1}>
                        No Players Yet
                    </Typography>
                </Grid>
            )}

            {/* todo add ability add player they've saved */}
            <Dialog open={dialogueOpen} onClose={handleCloseDialogue}>
                <DialogTitle>Add Player</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select from a list of saved customers or add a new player.
                    </DialogContentText>
                    <TextField
                        id='first-name'
                        label='First Name'
                        size='small'
                        value={newPlayerFirst}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewPlayerFirst(event.target.value);
                        }}
                        sx={{ width: 'calc(50% - 0.5rem)', mr: 1 }}
                    />
                    <TextField
                        id='last-name'
                        label='Last Name'
                        size='small'
                        value={newPlayerLast}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewPlayerLast(event.target.value);
                        }}
                        sx={{ width: '50%' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogue}>Cancel</Button>
                    <Button variant='contained' onClick={handleAddPlayer}>
                        Add Player
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PlayersList;
