import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { CardTitle } from './CardTitle';

ChartJS.register(ArcElement, Tooltip, Legend);

export const CourtCapacityDonuts = () => {
    const options = {
        responsive: false,
        // maintainAspectRatio: false,
        radius: 64,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };
    const exampleData = {
        labels: ['Busy', 'Open'],
        datasets: [
            {
                label: '% of Hours Today',
                data: [30, 70],
                backgroundColor: ['rgba(20,163,127, 0.75)', 'rgba(20,163,127, 0.15)'],
                // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                // borderWidth: 1,
            },
        ],
    };

    const data: Record<string, Record<string, any>> = {
        ['Indoor Courts']: {
            ['Indoor Court 1']: exampleData,
            ['Indoor Court 2']: exampleData,
            ['Indoor Court 3']: exampleData,
            ['Indoor Court 4']: exampleData,
            ['Indoor Court 5']: exampleData,
            // ['Indoor Court 6']: exampleData,
            ['Indoor Court 6a']: exampleData,
            ['Indoor Court 6b']: exampleData,
            // ['Indoor Court 9']: exampleData,
        },
        ['Outdoor Courts']: {
            ['Outdoor Court 1']: exampleData,
            ['Outdoor Court 2']: exampleData,
            ['Outdoor Court 3a']: exampleData,
            ['Outdoor Court 3b']: exampleData,
            ['Outdoor Court 4']: exampleData,
            ['Outdoor Court 5']: exampleData,
            ['Outdoor Court 6']: exampleData,
        },
    };

    return (
        <Card>
            <CardTitle title='Court Capacity' />
            <CardContent>
                <Stack spacing={2}>
                    {Object.keys(data).map((facility) => (
                        <Stack key={facility} spacing={1.5}>
                            <Typography key={facility} variant='subtitle1'>
                                {facility}:
                            </Typography>
                            <Grid container spacing={1.5}>
                                {Object.keys(data[facility]).map((courtName) => (
                                    <Grid item key={courtName}>
                                        <Box height='144px' width='144px'>
                                            <Typography
                                                key={courtName}
                                                sx={{ mb: 2, display: 'inherit' }}
                                                color='text.secondary'
                                                align='center'
                                                variant='overline'
                                            >
                                                {courtName}
                                            </Typography>
                                            <Box height='114px' width='144px'>
                                                <Typography
                                                    sx={{ mt: '36px', ml: '56px' }}
                                                    color='text.secondary'
                                                >
                                                    {data[facility][courtName]['datasets'][0]['data'][0]}%
                                                </Typography>
                                                <Doughnut
                                                    style={{
                                                        marginLeft: '-36px',
                                                        marginTop: '-65px',
                                                        height: '108px',
                                                        width: '216px',
                                                    }}
                                                    data={data[facility][courtName]}
                                                    options={options}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};
