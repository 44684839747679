import { Box, Grid, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Court } from '../../../common/types/Court';
import { EventInstance } from '../../../common/types/ScheduleEvent';
import ScheduleSwiper from './ScheduleSwiper';

interface CourtsSchedulesListProps {
    fullScheduleByCourt: { [key: string]: EventInstance[] };
    clientCourts: Court[];
    isLoading: boolean;
}

const CourtsSchedulesList = ({ fullScheduleByCourt, clientCourts, isLoading }: CourtsSchedulesListProps) => {
    return (
        <>
            {isLoading ? (
                <Box mt={5} mb={2}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='left' spacing={2}>
                                {[1, 2, 3, 4, 5, 6].map((_, index) => (
                                    <Grid key={index} item>
                                        <Skeleton variant='rectangular' width={240} height={150} />
                                    </Grid>
                                ))}
                                <Grid item sx={{ alignSelf: 'end' }}>
                                    <Skeleton
                                        sx={{ mr: 0.5, display: 'inline-block' }}
                                        variant='circular'
                                        width={20}
                                        height={20}
                                    />
                                    <Skeleton
                                        sx={{ mr: 0.5, display: 'inline-block' }}
                                        variant='circular'
                                        width={20}
                                        height={20}
                                    />
                                    <Skeleton
                                        sx={{ display: 'inline-block' }}
                                        variant='circular'
                                        width={20}
                                        height={20}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box className='easycourts-swiper'>
                    {Object.keys(fullScheduleByCourt)
                        .sort()
                        .map((courtId) => {
                            const court = clientCourts.find((court) => {
                                return court.courtId === courtId;
                            });
                            return (
                                <Box key={courtId}>
                                    <Typography variant='h5' gutterBottom>
                                        {court!!.courtName}
                                        <Typography variant='body1' sx={{ display: 'inline', ml: 2 }}>
                                            {court?.sport}
                                        </Typography>
                                    </Typography>

                                    <ScheduleSwiper scheduleOfEvents={fullScheduleByCourt[courtId]} />
                                </Box>
                            );
                        })}
                </Box>
            )}
        </>
    );
};

export default CourtsSchedulesList;
