import { Backdrop, Box, Button, CircularProgress, Drawer } from '@mui/material';
// import { TimePicker } from '@mui/x-date-pickers';
// import { DateTime } from 'luxon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';

interface DrawerWithChangesDialogueProps {
    drawerOpen: boolean;
    onDrawerClose: () => void;
    isDirty: () => boolean;
    onSaveChanges: () => void;
    //todo make isLoading not optional
    isLoading?: boolean;
    children: React.ReactNode;
}

const DrawerWithChangesDialogue = ({
    drawerOpen,
    onDrawerClose,
    isDirty,
    onSaveChanges,
    isLoading = false,
    children,
}: DrawerWithChangesDialogueProps) => {
    const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);

    function handleCloseDrawer() {
        if (isDirty()) {
            setDialogueOpen(true);
        } else {
            onDrawerClose();
        }
    }

    function onDiscard() {
        setDialogueOpen(false);
        onDrawerClose();
    }

    function onGoBack() {
        setDialogueOpen(false);
    }

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color='inherit' />

                <Drawer anchor={'right'} open={drawerOpen} onClose={handleCloseDrawer}>
                    {children}
                    <Box p={3} width={'600px'}>
                        <Button
                            variant='outlined'
                            sx={{ mt: 3, mr: 1, float: 'right' }}
                            onClick={handleCloseDrawer}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ mt: 3, mr: 1, float: 'right' }}
                            onClick={onSaveChanges}
                        >
                            Save
                        </Button>
                    </Box>
                </Drawer>
            </Backdrop>

            <Dialog open={dialogueOpen}>
                <DialogTitle>You Have Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>Do you want to go back or discard your changes?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDiscard}>Discard</Button>
                    <Button onClick={onGoBack} autoFocus>
                        Go Back
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DrawerWithChangesDialogue;
