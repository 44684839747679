import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const Payments = () => {
    return (
        <div className='dashboard-payments'>
            <Typography variant='h3' gutterBottom>
                Payments
            </Typography>
            <Typography variant='h6'>User stories to accomplish here:</Typography>
            <Typography paragraph>1. Operaor comes to configure how their payment works</Typography>
            <Typography paragraph>2. Operator comes to see the history of account transactions</Typography>
            <Divider sx={{ mb: 2 }} />
        </div>
    );
};

export default Payments;
