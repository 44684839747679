import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export const CardTitle = ({ title }: { title: string }) => {
    return (
        <Box
            sx={{
                height: '48px',
                width: '100%',
                backgroundColor: grey[200],
                mb: 1,
            }}
        >
            <Typography variant='subtitle1' pt={1.5} textAlign={'center'} color={grey[800]}>
                {title.toUpperCase()}
            </Typography>
        </Box>
    );
};
