import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Stack,
    useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import EventDetailsDrawer from '../Schedule/EventDetails/EventDetailsDrawer';
import { CourtCapacityDonuts } from './CourtCapacityDonuts';
import EventsMetricsCards from './EventsMetricsCards';
import { HappeningSoon } from './HappeningSoon';
import SalesByEventType from './SalesByEventType';

const Summary = () => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<any>(null);

    //todo fix this, clicking the details button doesnt yet work
    // function toggleDrawer(event: AnyEvent) {
    //     if (!drawerOpen) {
    //         setEvent(event);
    //         setDrawerOpen(true);
    //     } else {
    //         setDrawerOpen(false);
    //         setEvent(null);
    //     }
    // }
    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }

    const isLargeScreen = useMediaQuery('(min-width: 1400px)');
    return (
        <>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>User stories to accomplish here:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>
                        This is a summary screen to see high level information about the day.
                    </Typography>
                    <Typography paragraph>
                        This is not for editing more granular details of events. For example, if a user wants
                        to edit a clinic's description and the repeat occurrances, they should do it in the
                        Configure Clinics view.
                    </Typography>
                    <Typography paragraph>
                        1. View whats happening today: events, summarized event info
                    </Typography>
                    <Typography paragraph>2. View whats happening today: staff working</Typography>
                    <Typography paragraph>3. View how business is perofrorming</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>Court 1 is booked 5 times today</Typography>
                    <Typography paragraph>Charts to indicate how booked each court is today</Typography>
                    <Typography paragraph>Pros working today: Dude, Sweet</Typography>
                    <Typography paragraph>Avatars for pros</Typography>
                    <Typography paragraph>10 people are signed up for clinics today</Typography>
                    <Typography paragraph>Action button to bring up clinic check in view</Typography>
                    <Typography paragraph>5 people are signed up for lessons today</Typography>
                    <Typography paragraph>Action button to bring up lesson check in view</Typography>
                    <Typography paragraph>5 people are signed up for courts today</Typography>
                    <Typography paragraph>Action button to bring up reservation check in view</Typography>
                    <Typography paragraph>Etc stuff like this</Typography>
                </AccordionDetails>
            </Accordion>

            <Box mb={2}>
                <Grid sx={{ flexGrow: 1 }} container spacing={3}>
                    {isLargeScreen && (
                        <>
                            <Grid item xs={3} lg>
                                <HappeningSoon onClickDetails={toggleDrawer} />
                            </Grid>
                            <Grid item xs={3}>
                                <EventsMetricsCards />
                            </Grid>

                            <Grid item xs={6}>
                                <Stack spacing={3}>
                                    <CourtCapacityDonuts />
                                    <SalesByEventType />
                                </Stack>
                            </Grid>
                        </>
                    )}

                    {!isLargeScreen && (
                        <>
                            <Grid item xs={4} lg>
                                <HappeningSoon onClickDetails={toggleDrawer} />
                            </Grid>
                            <Grid item xs={8}>
                                <EventsMetricsCards />

                                <Stack spacing={3}>
                                    <CourtCapacityDonuts />
                                    <SalesByEventType />
                                </Stack>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
            {event && (
                <EventDetailsDrawer event={event} drawerOpen={drawerOpen} onDrawerClose={toggleDrawer} />
            )}
        </>
    );
};

export default Summary;
