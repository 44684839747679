import { grey } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

//todo: i just picked some colors for now
//i bet that we could find some sort of predefined MUI ThemeOptions somewhere
export const EasyCourtsTheme: ThemeOptions = {
    palette: {
        primary: {
            main: '#000000',
            light: '#00E4B4',
            dark: '#00C29A',
        },
        // secondary: {
        //     main: '#000000',
        //     light: '#00E4B4',
        //     dark: '#00C29A'
        // }
        // info: {
        //     main: '#000000',
        //     light: '#00E4B4',
        //     dark: '#00C29A'
        // }
        background: {
            paper: grey[50],
            default: grey[100],
        },
    },
};
