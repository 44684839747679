import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDeleteCourt } from '../../../../common/hooks/api/courts/useDeleteCourt';
import { Court } from '../../../../common/types/Court';
import ConfigureCourtDrawer from './ConfigureCourtDrawer';

interface CourtGridCardProps {
    court: Court;
}

const CourtGridCard = ({ court }: CourtGridCardProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
    const { deleteCourtAsync, isLoading } = useDeleteCourt();

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }
    function toggleDialogue() {
        setDialogueOpen(!dialogueOpen);
    }

    function onDeleteCourt() {
        deleteCourtAsync(court.courtId);
        toggleDialogue();
    }

    return (
        <>
            <Card
                key={court.courtId}
                sx={{
                    width: '240px',
                }}
            >
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                <CardContent>
                    <Typography variant='h6'>{court.courtName}</Typography>
                    <Typography color='text.secondary'>Sport: {court.sport}</Typography>
                    <Typography color='text.secondary'>Location: {court.courtLocation}</Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={toggleDrawer}>
                        Edit
                    </Button>
                    <Button size='small' onClick={toggleDialogue}>
                        Delete
                    </Button>
                </CardActions>
            </Card>
            <ConfigureCourtDrawer court={court} drawerOpen={drawerOpen} onDrawerClose={toggleDrawer} />
            <Dialog open={dialogueOpen}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    {/* todo put the court facility and name in delete confirmation dialogue */}
                    <DialogContentText>Do you want to delete this court?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDeleteCourt}>Delete</Button>
                    <Button onClick={toggleDialogue} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CourtGridCard;
