import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { ScheduleEventCategoryEnum } from '../../../common/types/ScheduleEvent';

interface EventCategorySelectorProps {
    selectedEventCategory: 'all' | ScheduleEventCategoryEnum;
    setSelectedEventCategory: (eventType: 'all' | ScheduleEventCategoryEnum) => void;
}

const EventCategorySelector = ({
    selectedEventCategory,
    setSelectedEventCategory,
}: EventCategorySelectorProps) => {
    function handleFilterChange(
        event: React.MouseEvent<HTMLElement>,
        eventType: 'all' | ScheduleEventCategoryEnum
    ) {
        setSelectedEventCategory(eventType);
    }

    return (
        <ToggleButtonGroup
            color='primary'
            value={selectedEventCategory}
            exclusive
            size='large'
            onChange={handleFilterChange}
            sx={{ ml: 2 }}
        >
            <ToggleButton value='all'>All</ToggleButton>
            <ToggleButton value={ScheduleEventCategoryEnum.Reservation}>Reservations</ToggleButton>
            <ToggleButton value={ScheduleEventCategoryEnum.ClinicInstance}>Clinics</ToggleButton>
            <ToggleButton value={ScheduleEventCategoryEnum.Lesson}>Lessons</ToggleButton>
            {/* todo: Not fully checked in, not fully paid, not fully staffed */}
            {/* todo should we allow checkboxes for multi filter select? */}
            {/* like lessons that are not fully staffed */}
            {/* maybe that view should be in the lessons view? */}
        </ToggleButtonGroup>
    );
};

export default EventCategorySelector;
