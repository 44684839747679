import { CourtSportEnum } from './Court';
import { Player } from './Player';
import { ProStaff } from './ProStaff';

export enum ScheduleEventCategoryEnum {
    Clinic = 'Clinic',
    ClinicInstance = 'Clinic Class',
    Reservation = 'Reservation',
    Lesson = 'Lesson',
    Closed = 'Closed',
}

export const ScheduleEventCategoryEnumArray = [
    ScheduleEventCategoryEnum.ClinicInstance,
    ScheduleEventCategoryEnum.Reservation,
    ScheduleEventCategoryEnum.Lesson,
    ScheduleEventCategoryEnum.Closed,
];

export type ISODateStr = string;

export type DateRangeType = {
    start: ISODateStr; //iso string
    end: ISODateStr; //iso string
};

//most general parent class
//most granular level of event
//an instance of an event
class ScheduleEvent {
    eventId: string;
    clientId: string;
    facilityId: string;
    courtId: string;
    timeSlot: DateRangeType;
    eventCategory: ScheduleEventCategoryEnum;
    eventName: string;
    signedUpPlayers: Player[];
    checkedInPlayers: Player[];
    paidPlayers: Player[];
    maxPlayers: number;
    releaseDate: string;

    constructor(
        // eventId: string, //todo maybe use uuid
        clientId: string,
        facilityId: string,
        courtId: string,
        timeSlot: DateRangeType,
        eventCategory: ScheduleEventCategoryEnum,
        eventName: string,
        signedUpPlayers: Player[],
        checkedInPlayers: Player[],
        paidPlayers: Player[],
        maxPlayers: number,
        releaseDate: string
    ) {
        this.eventId = `${facilityId}-${courtId[0]}-${eventCategory}-${timeSlot.start}}`;
        this.clientId = clientId;
        this.facilityId = facilityId;
        this.courtId = courtId;
        this.timeSlot = timeSlot;
        this.eventCategory = eventCategory;
        this.eventName = eventName;
        this.signedUpPlayers = signedUpPlayers;
        this.maxPlayers = maxPlayers;
        this.checkedInPlayers = checkedInPlayers;
        this.paidPlayers = paidPlayers;
        this.releaseDate = releaseDate;
    }
}

export class ClinicInstance extends ScheduleEvent {
    //clinic specific (copied from clinic)
    parentClinicId: string;
    clinicDescription: string;
    clinicSport: CourtSportEnum;
    //clinic instance specific (copied from clinic)
    instancePrice: number;
    instanceStaff: ProStaff[];

    constructor(
        // eventId: string,
        clientId: string,
        facilityId: string,
        courtId: string,
        timeSlot: DateRangeType,
        eventName: string,
        signedUpPlayers: Player[],
        checkedInPlayers: Player[],
        paidPlayers: Player[],
        maxPlayers: number,
        releaseDate: string,
        //clinic specific (copied from clinic)
        parentClinicId: string,
        clinicDescription: string,
        clinicSport: CourtSportEnum,
        //clinic instance specific
        instancePrice: number,
        instanceStaff: ProStaff[]
    ) {
        super(
            // eventId,
            clientId,
            facilityId,
            courtId,
            timeSlot,
            ScheduleEventCategoryEnum.ClinicInstance,
            eventName,
            signedUpPlayers,
            checkedInPlayers,
            paidPlayers,
            maxPlayers,
            releaseDate
        );
        this.parentClinicId = parentClinicId;
        this.clinicDescription = clinicDescription;
        this.clinicSport = clinicSport;
        this.instancePrice = instancePrice;
        this.instanceStaff = instanceStaff;
    }
}

export type RepeatOccuranceConfig = {
    repeatQty: number;
    repeatOnDays: string[];
};

export class Clinic {
    //event-like properties
    clientId: string;
    facilityId: string;
    timeSlot: DateRangeType;
    eventCategory: ScheduleEventCategoryEnum;
    eventName: string;
    signedUpPlayers: Player[];
    paidPlayers: Player[];
    maxPlayers: number;
    releaseDate: string;
    //clinic instance specific (copy to instance)
    instancePrice: number;
    //clinic non-instance specific
    clinicDescription: string;
    clinicSport: CourtSportEnum;
    clinicId: string;
    clinicStaff: ProStaff[];
    repeatOccuranceConfig: RepeatOccuranceConfig;
    courtIds: string[];
    totalPrice: number;

    constructor(
        clientId: string,
        facilityId: string,
        timeSlot: DateRangeType,
        eventName: string,
        signedUpPlayers: Player[],
        paidPlayers: Player[],
        maxPlayers: number,
        releaseDate: string,
        //clinic instance specific (copy to instance)
        instancePrice: number,
        //clinic non-instance specific
        clinicDescription: string,
        clinicSport: CourtSportEnum,
        clinicId: string,
        clinicStaff: ProStaff[],
        repeatOccuranceConfig: RepeatOccuranceConfig,
        courtIds: string[],
        totalPrice: number
    ) {
        this.clientId = clientId;
        this.facilityId = facilityId;
        this.timeSlot = timeSlot;
        this.eventCategory = ScheduleEventCategoryEnum.Clinic;
        this.eventName = eventName;
        this.signedUpPlayers = signedUpPlayers;
        this.maxPlayers = maxPlayers;
        this.paidPlayers = paidPlayers;
        this.releaseDate = releaseDate;

        //clinic instance specific (copy to instance)
        this.instancePrice = instancePrice;

        //clinic non-instance specific
        this.clinicDescription = clinicDescription;
        this.clinicSport = clinicSport;
        this.clinicId = clinicId;
        this.clinicStaff = clinicStaff;
        this.repeatOccuranceConfig = repeatOccuranceConfig;
        this.courtIds = courtIds;
        this.totalPrice = totalPrice;
    }
}

export class Reservation extends ScheduleEvent {
    price: number;

    constructor(
        // eventId: string,
        clientId: string,
        facilityId: string,
        courtId: string,
        timeSlot: DateRangeType,
        eventName: string,
        signedUpPlayers: Player[],
        checkedInPlayers: Player[],
        paidPlayers: Player[],
        maxPlayers: number,
        releaseDate: string,
        //reservation specific
        price: number
    ) {
        super(
            // eventId,
            clientId,
            facilityId,
            courtId,
            timeSlot,
            ScheduleEventCategoryEnum.Reservation,
            eventName,
            signedUpPlayers,
            checkedInPlayers,
            paidPlayers,
            maxPlayers,
            releaseDate
        );
        this.price = price;
    }
}

export class Lesson extends ScheduleEvent {
    lessonDescription: string;
    lessonSport: CourtSportEnum;
    lessonPrice: number;
    lessonStaff: ProStaff[];

    constructor(
        eventId: string,
        clientId: string,
        facilityId: string,
        courtId: string,
        timeSlot: DateRangeType,
        eventName: string,
        signedUpPlayers: Player[],
        checkedInPlayers: Player[],
        paidPlayers: Player[],
        maxPlayers: number,
        releaseDate: string,
        //lesson specific
        lessonDescription: string,
        lessonSport: CourtSportEnum,
        lessonPrice: number,
        lessonStaff: ProStaff[]
    ) {
        super(
            // eventId,
            clientId,
            facilityId,
            courtId,
            timeSlot,
            ScheduleEventCategoryEnum.Lesson, //eventCategory
            eventName,
            signedUpPlayers,
            checkedInPlayers,
            paidPlayers,
            maxPlayers,
            releaseDate
        );
        this.lessonDescription = lessonDescription;
        this.lessonSport = lessonSport;
        this.lessonPrice = lessonPrice;
        this.lessonStaff = lessonStaff;
    }
}

export class Closed extends ScheduleEvent {
    constructor(
        // eventId: string,
        clientId: string,
        facilityId: string,
        courtId: string,
        timeSlot: DateRangeType,
        eventName: string,
        releaseDate: string
    ) {
        super(
            // eventId,
            clientId,
            facilityId,
            courtId,
            timeSlot,
            ScheduleEventCategoryEnum.Closed,
            eventName,
            [], //signedUpPlayers
            [], //checkedInPlayers
            [], //paidPlayers
            0, //maxPlayers
            releaseDate
        );
    }
}

export type EventInstance = ClinicInstance | Reservation | Lesson | Closed;
export type AnyEvent = Clinic | EventInstance; //ClinicInstance | Reservation | Lesson | Closed;
