import { Button, Card, CardActions } from '@mui/material';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { EventInstance, ScheduleEventCategoryEnum } from '../../../common/types/ScheduleEvent';
import EventDetailsDrawer from './EventDetails/EventDetailsDrawer';
import ScheduleEventCardContent from './ScheduleEventCardContent';

interface ScheduleSwiperEventProps {
    event: EventInstance;
    width: () => string;
}

const ScheduleSwiperEvent = ({ event, width }: ScheduleSwiperEventProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [addEventDetailsToDom, setAddEventDetailsToDom] = useState<boolean>(false);

    const showAsTaken = (event: EventInstance) => {
        if (event.eventCategory === ScheduleEventCategoryEnum.Reservation) {
            if (event.signedUpPlayers.length > 0) {
                return true;
            }
        } else if (event.eventCategory === ScheduleEventCategoryEnum.Closed) {
            return true;
        } else if (event.signedUpPlayers.length >= event.maxPlayers) {
            return true;
        }
        return false;
    };

    function toggleDrawer() {
        setAddEventDetailsToDom(!addEventDetailsToDom);
        setTimeout(() => {
            setDrawerOpen(!drawerOpen);
        }, 10);
    }

    return (
        <>
            <Card
                onClick={toggleDrawer}
                sx={{
                    minWidth: width(),
                    bgcolor: showAsTaken(event) ? '#abc' : '#fff',
                }}
            >
                <ScheduleEventCardContent event={event} />
                <CardActions>
                    <Button size='small'>Details</Button>
                </CardActions>
            </Card>{' '}
            {addEventDetailsToDom && (
                <EventDetailsDrawer event={event} drawerOpen={drawerOpen} onDrawerClose={toggleDrawer} />
            )}
        </>
    );
};

export default ScheduleSwiperEvent;
