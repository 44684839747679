import { useQuery } from '@tanstack/react-query';
import { MOCK_PROS } from '../../../mockData/mockPros';
import { QueryConstants } from '../../constants/QueryContstants';

export const useStaff = (clientId: string) => {
    const { data: staff, isLoading: isLoadingStaff } = useQuery({
        queryKey: [QueryConstants.CLIENT_STAFF],
        queryFn: () =>
            fetch('https://jsonplaceholder.typicode.com/photos').then((res) => {
                return MOCK_PROS;
                // return res.json();
            }),
    });
    return { staff, isLoadingStaff };
};
