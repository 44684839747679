import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/system';
import { Outlet } from 'react-router';
import { useAuthContext } from '../../common/hooks/useAuthContext';
import SideNavBar from './SideNavBar';

const DashboardContainer = () => {
    const { user } = useAuthContext();
    // if (!user) return null;
    const { user: auth0User } = useAuth0();
    if (!auth0User) return null;

    return (
        <Box sx={{ display: 'flex' }}>
            <SideNavBar />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default DashboardContainer;
