import { useQuery } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { Court } from '../../../types/Court';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useCourts = (clientId: string): { courts: Court[]; isLoadingCourts: boolean } => {
    const { data: courts, isLoading: isLoadingCourts } = useQuery({
        queryKey: [QueryConstants.CLIENT_COURTS],
        queryFn: () =>
            // fetch('https://jsonplaceholder.typicode.com/comments').then((res) => {
            //     return MOCK_COURTS.filter((court) => court.clientId === clientId);
            // }),
            fetch(`${CommonUtils.getBaseUrl()}/courts/${clientId}`).then((res) => {
                return res.json();
            }),
    });
    return { courts, isLoadingCourts };
};
