import { Box, Divider, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import DrawerWithChangesDialogue from '../../../../common/components/DrawerWithChangesDialogue';
import { useCreateFacility } from '../../../../common/hooks/api/facilities/useCreateFacility';
import { useUpdateFacility } from '../../../../common/hooks/api/facilities/useUpdateFacility';
import { Facility } from '../../../../common/types/Facility';

interface ConfigureFacilityDrawerProps {
    facility: Facility;
    drawerOpen: boolean;
    onDrawerClose: () => void;
}

const ConfigureFacilityDrawer = ({ facility, drawerOpen, onDrawerClose }: ConfigureFacilityDrawerProps) => {
    //todo fix hardcode client id
    const { updateFacilityAsync, isLoading: isUpdating } = useUpdateFacility();
    //todo fix hardcode client id
    const { createFacilityAsync, isLoading: isCreating } = useCreateFacility();
    const [facilityName, setFacilityName] = useState<string>(facility.facilityName);
    const [facilityOpenTime, setFacilityOpenTime] = useState<DateTime | null>(
        DateTime.fromFormat(facility.facilityOpenTime, 't')
    );
    const [facilityCloseTime, setFacilityCloseTime] = useState<DateTime | null>(
        DateTime.fromFormat(facility.facilityCloseTime, 't')
    );

    const newOrEditedFacility = useMemo(() => {
        const newOrEditedFacility: Facility = {
            ...facility,
            facilityName,
            facilityOpenTime: facilityOpenTime?.toFormat('t') || '',
            facilityCloseTime: facilityCloseTime?.toFormat('t') || '',
        };

        return newOrEditedFacility;
    }, [facility, facilityName, facilityOpenTime, facilityCloseTime]);

    async function onSaveChanges() {
        if (facility.facilityId === '') {
            await createFacilityAsync(newOrEditedFacility);
        } else {
            await updateFacilityAsync(newOrEditedFacility);
        }
        onDrawerClose();
    }

    function isDirty() {
        //todo it seems to always think isDirty is true
        //might be related to _id existing from mongo, idk
        return JSON.stringify(newOrEditedFacility) !== JSON.stringify(facility);
    }

    return (
        <DrawerWithChangesDialogue
            drawerOpen={drawerOpen}
            onDrawerClose={onDrawerClose}
            isDirty={isDirty}
            onSaveChanges={onSaveChanges}
            isLoading={isUpdating || isCreating}
        >
            <Box p={3} width={'600px'}>
                <Typography variant='h5'>Facility Info</Typography>
                <Divider sx={{ mt: 1, mb: 4 }} />

                <Grid container spacing={1} p={1}>
                    <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        label='Facility Name'
                        value={facilityName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFacilityName(event.target.value);
                        }}
                    />

                    <TimePicker
                        sx={{ width: 'calc(50% - 0.5rem)', mb: 3, mr: 2 }}
                        label='Open Time'
                        value={facilityOpenTime}
                        onChange={(newValue) => setFacilityOpenTime(newValue)}
                    />
                    <TimePicker
                        sx={{ width: 'calc(50% - 0.5rem)', mb: 3 }}
                        label='Close Time'
                        value={facilityCloseTime}
                        onChange={(newValue) => setFacilityCloseTime(newValue)}
                    />
                </Grid>
            </Box>
        </DrawerWithChangesDialogue>
    );
};

export default ConfigureFacilityDrawer;
