import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryConstants } from '../../../constants/QueryContstants';
import { CommonUtils } from '../../../utils/CommonUtils';

export const useDeleteCourt = () => {
    const queryClient = useQueryClient();
    const {
        mutate: deleteCourt,
        mutateAsync: deleteCourtAsync,
        isLoading,
        // isSuccess,
    } = useMutation({
        mutationFn: (courtId: string) => {
            return fetch(`${CommonUtils.getBaseUrl()}/courts/${courtId}`, {
                method: 'DELETE',
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_COURTS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryConstants.CLIENT_FACILITIES],
            });
        },
    });

    return { deleteCourt, deleteCourtAsync, isLoading };
};
