import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { CourtSportEnum } from '../../../common/types/Court';
import { Clinic, ScheduleEventCategoryEnum } from '../../../common/types/ScheduleEvent';
import ConfigureClinicDrawer from './ConfigureClinicDrawer';

interface AddClinicGridCardProps {
    onAddNewClinic: (clinic: Clinic) => void;
}

const AddClinicGridCard = ({ onAddNewClinic }: AddClinicGridCardProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    function onDrawerClose() {
        setDrawerOpen(false);
    }

    const clinic: Clinic = {
        clientId: '',
        // eventId: '',
        facilityId: '',
        timeSlot: {
            start: DateTime.now().endOf('hour').toISO() || '',
            end: DateTime.now().endOf('hour').toISO() || '',
        },
        eventCategory: ScheduleEventCategoryEnum.Clinic,
        eventName: '',
        signedUpPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '',
        //clinic specific
        clinicDescription: '',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 0,
        clinicStaff: [],
        //clinic non-instance specific
        clinicId: '',
        totalPrice: 0,
        repeatOccuranceConfig: {
            repeatQty: 1,
            repeatOnDays: [],
        },
        courtIds: [],
    };

    return (
        <>
            <Card
                key={'add-clinic'}
                sx={{
                    height: '159px',
                    width: '240px',
                }}
            >
                <CardContent>
                    <Typography variant='h6'>Create New Clinic</Typography>
                </CardContent>
                <CardActions>
                    <Button size='small' onClick={() => {}}>
                        <AddIcon />
                    </Button>
                </CardActions>
            </Card>
            <ConfigureClinicDrawer
                clinic={clinic}
                onSave={onAddNewClinic}
                drawerOpen={drawerOpen}
                onDrawerClose={onDrawerClose}
            />
        </>
    );
};

export default AddClinicGridCard;
