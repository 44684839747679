import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useSwiperCads = () => {
    const theme = useTheme();
    const atLeastMdWidth = useMediaQuery(theme.breakpoints.up('md'));
    const atLeastLgWidth = useMediaQuery(theme.breakpoints.up('lg'));
    const atLeastXlWidth = useMediaQuery(theme.breakpoints.up('xl'));

    function getSlidesPerView() {
        if (atLeastXlWidth) {
            return 6;
        } else if (atLeastLgWidth) {
            return 5;
        } else if (atLeastMdWidth) {
            return 4;
        } else {
            return 3;
        }
    }

    function getCardWidth() {
        if (atLeastXlWidth) {
            return 'calc(85vw / 6)';
        }
        if (atLeastLgWidth) {
            return 'calc(85vw / 5)';
        }
        if (atLeastMdWidth) {
            return 'calc(85vw / 4)';
        }
        return 'calc(85vw / 3)';
    }

    return { getSlidesPerView, getCardWidth };
};
