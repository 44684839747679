import { CourtSportEnum } from '../common/types/Court';
import { Clinic, EventInstance, ScheduleEventCategoryEnum } from '../common/types/ScheduleEvent';
import { MOCK_PLAYERS } from './mockPlayers';
import { MOCK_PROS } from './mockPros';

const MOCK_TODAY = new Date();
MOCK_TODAY.setHours(0, 0, 0, 0);

//todo: these are not linked to the other mock data, just hardcoded client and facility ids
export const MOCK_SCHEDULED_EVENTS: EventInstance[] = [
    //Client 1 Facility 1 Court 1
    {
        eventId: 'event-1',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(8)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(9)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Beginner Clinic',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-1',
        clinicDescription: 'This is a clinic for beginners',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [MOCK_PROS[3]],
    },
    {
        eventId: 'event-2',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(10)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(11)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Beginnger Clinic',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-1',
        clinicDescription: 'This is a clinic for 3.5+ players',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [],
    },
    {
        eventId: 'event-3',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Advanced Clinic',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-2',
        clinicDescription: 'This is a clinic for 3.5+ players',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [],
    },
    {
        eventId: 'event-4',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(13)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(14)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Advanced Clinic',
        signedUpPlayers: [MOCK_PLAYERS[0], MOCK_PLAYERS[1], MOCK_PLAYERS[2], MOCK_PLAYERS[3]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [MOCK_PLAYERS[0]],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-2',
        clinicDescription: 'This is a clinic for 3.5+ players',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [],
    },
    {
        eventId: 'event-5',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(16)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(17)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-6',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(17)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(18)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 1,
        releaseDate: '2023-01-01',
        //lesson-specific
        lessonDescription: 'This is a private lesson',
        lessonSport: CourtSportEnum.Tennis,
        lessonPrice: 150,
        lessonStaff: [],
    },

    //Client 1 Facility 1 Court 2
    {
        eventId: 'event-7',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Advanced Clinic',
        signedUpPlayers: [MOCK_PLAYERS[0], MOCK_PLAYERS[1], MOCK_PLAYERS[2], MOCK_PLAYERS[3]],
        checkedInPlayers: [MOCK_PLAYERS[0], MOCK_PLAYERS[1], MOCK_PLAYERS[2], MOCK_PLAYERS[3]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-2',
        clinicDescription: 'This is a clinic for 3.5+ players',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [],
    },
    {
        eventId: 'event-8',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(15)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(16)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-8a',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(15)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(16)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
        lessonPrice: 100,
    },
    {
        eventId: 'event-9',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-10',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(16)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(18)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },

    //Client 1 Facility 1 Court 3
    {
        eventId: 'event-11',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-3',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(16)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(17)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-12',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-3',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(17)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(18)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 1,
        releaseDate: '2023-01-01',
        //lesson-specific
        lessonDescription: 'This is a private lesson',
        lessonSport: CourtSportEnum.Tennis,
        lessonStaff: [],
        lessonPrice: 150,
    },
    {
        eventId: 'event-10a',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-3',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(17)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(18)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-10b',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-3',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(18)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(19)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-10c',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-3',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(19)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(20)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
        lessonPrice: 100,
    },

    //Client 1 Facility1 Court 4
    {
        eventId: 'event-51',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(9)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(10)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-52',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(10)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(11)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-53',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(11)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(12)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-54',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-55',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(13)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(14)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-56',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-4',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },

    //Client 1 Facility1 Court 5
    {
        eventId: 'event-57',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(9)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(10)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-58',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(10)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(11)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-59',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(11)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(12)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-60',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-61',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(13)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(14)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-62',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-5',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },

    //Client 1 Facility1 Court 6
    {
        eventId: 'event-63',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(9)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(10)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-64',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(10)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(11)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-65',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(11)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(12)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-66',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(11)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(12)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-67',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-68',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(13)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(14)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },
    {
        eventId: 'event-69',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        courtId: 'client-1-facility-1-court-6',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Reservation,
        eventName: 'Reserved Court',
        signedUpPlayers: [MOCK_PLAYERS[0]],
        checkedInPlayers: [MOCK_PLAYERS[0]],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //reservation-specific
        price: 100,
    },

    //Client 1 Facility 2 Court 1
    {
        eventId: 'event-13',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(8)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(9)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-14',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(10)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(11)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 1,
        releaseDate: '2023-01-01',
        //lesson-specific
        lessonDescription: 'This is a private lesson',
        lessonSport: CourtSportEnum.Tennis,
        lessonPrice: 150,
        lessonStaff: [],
    },
    {
        eventId: 'event-14a',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Lesson,
        eventName: 'Private Lesson',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 1,
        releaseDate: '2023-01-01',
        //lesson-specific
        lessonDescription: 'This is a private lesson',
        lessonSport: CourtSportEnum.Tennis,
        lessonPrice: 150,
        lessonStaff: [],
    },

    //Client 1 Facility 2 Court 2
    {
        eventId: 'event-15',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(12)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(13)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.ClinicInstance,
        eventName: 'Beginner Clinic',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        parentClinicId: 'clinic-1',
        clinicDescription: 'This is a clinic for beginner players',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        instanceStaff: [],
    },
    {
        eventId: 'event-16',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(14)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(15)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-17',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(16)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(18)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
    {
        eventId: 'event-18',
        clientId: 'client-1',
        facilityId: 'client-1-facility-2',
        courtId: 'client-1-facility-2-court-2',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(8)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(9)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Closed,
        eventName: 'Closed',
        signedUpPlayers: [],
        checkedInPlayers: [],
        paidPlayers: [],
        maxPlayers: 0,
        releaseDate: '2023-01-01',
    },
];

export const MOCK_CLINICS: Clinic[] = [
    {
        // eventId: 'event-1',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(8)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(9)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Clinic,
        eventName: 'Beginner Clinic',
        signedUpPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        clinicDescription: 'This is a clinic for beginners',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        clinicStaff: [MOCK_PROS[3]],
        //non-clinic-instance-specific
        clinicId: 'clinic-1',
        courtIds: ['client-1-facility-1-court-1', 'client-1-facility-1-court-2'],
        repeatOccuranceConfig: {
            repeatQty: 1,
            repeatOnDays: [],
        },
        totalPrice: 1000,
    },
    {
        // eventId: 'event-1',
        clientId: 'client-1',
        facilityId: 'client-1-facility-1',
        timeSlot: {
            start: new Date(MOCK_TODAY.setHours(8)).toISOString(),
            end: new Date(MOCK_TODAY.setHours(9)).toISOString(),
        },
        eventCategory: ScheduleEventCategoryEnum.Clinic,
        eventName: 'Advanced Clinic',
        signedUpPlayers: [],
        paidPlayers: [],
        maxPlayers: 4,
        releaseDate: '2023-01-01',
        //clinic-specific
        clinicDescription: 'This is a clinic for 3.5+ plaers',
        clinicSport: CourtSportEnum.Tennis,
        instancePrice: 40,
        clinicStaff: [MOCK_PROS[3]],
        //non-clinic-instance-specific
        clinicId: 'clinic-2',
        courtIds: ['client-1-facility-1-court-1', 'client-1-facility-1-court-2'],
        repeatOccuranceConfig: {
            repeatQty: 1,
            repeatOnDays: [],
        },
        totalPrice: 1000,
    },
];
