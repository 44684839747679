import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { useCourts } from '../../../common/hooks/api/courts/useCourts';
import { useFacilities } from '../../../common/hooks/api/facilities/useFacilities';
import { Facility } from '../../../common/types/Facility';
import CourtCardGrid from './Courts/CourtCardGrid';
import FacilityCardGrid from './Facilities/FacilityCardGrid';

const Configure = () => {
    const { facilities: clientFacilities } = useFacilities('client-1');
    const { courts: clientCourts } = useCourts('client-1');

    const [selectedFacility, setSelectedFacility] = useState<Facility | undefined>(
        (clientFacilities || [])[0]
    );
    const facilityCourts = useMemo(() => {
        return (clientCourts || []).filter((c) => c.facilityId === selectedFacility?.facilityId);
    }, [clientCourts, clientFacilities, selectedFacility]);

    function onSelectFacility(facility: Facility) {
        setSelectedFacility(facility);
    }

    //todo figure out the best card width for most common brwoser widths, to minimize the empty space on the right

    return (
        <div className='dashboard-configure'>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>User stories to accomplish here:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>This is for </Typography>
                    <Typography paragraph>This is not for </Typography>
                    <Typography paragraph>1. </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ mb: 3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>How:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography paragraph>1.</Typography>

                    <Divider sx={{ mb: 1 }} />

                    <Typography paragraph>16. Click add new card to create new clinic</Typography>
                </AccordionDetails>
            </Accordion>

            <Typography variant='h3' gutterBottom>
                Facilities
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Box mb={2}>
                <FacilityCardGrid facilities={clientFacilities || []} onSelectFacility={onSelectFacility} />
            </Box>
            {selectedFacility && (
                <Box>
                    <Typography variant='h5' gutterBottom>
                        Courts for: {selectedFacility.facilityName}{' '}
                        <Typography variant='body1' sx={{ float: 'right', mr: 3 }}>
                            {facilityCourts.length} Courts
                        </Typography>
                    </Typography>
                    <Divider sx={{ mb: 3 }} />

                    {facilityCourts && (
                        <Box className='easycourts-swiper'>
                            <CourtCardGrid facilityId={selectedFacility.facilityId} courts={facilityCourts} />
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
};

export default Configure;
